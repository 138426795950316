import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import { useTranslate } from "../services/appLanguageService";

const styles = (theme: any) =>
  createStyles({
    notes: {
      padding: 20,
      marginTop: 20,
      marginBottom: 20,
      overflow: "hidden"
    },
    editButton: {
      float: "right"
    },
    doneButton: {
      float: "right"
    }
  });

interface EditableNotesProps extends WithStyles<typeof styles> {
  customLabel?: string;
  notes: any;
  resourceId: number;
  saveNotes: (resourceId: number, notes: string | null) => void;
  readonly?: boolean;
}

const EditableNotes: React.FC<EditableNotesProps> = (props) => {
  const { classes, resourceId, customLabel } = props;

  const [notesEditable, setNotesEditable] = useState(false);
  const [notes, setNotes] = useState(props.notes);

  useEffect(() => setNotes(props.notes), [resourceId]);

  const t = useTranslate("EditableNotes");

  const saveNotes = () => {
    props.saveNotes(resourceId, notes);
    setNotesEditable(false);
  };

  const readonly = typeof props.readonly !== "undefined" && props.readonly;

  return (
    <Paper className={classes.notes}>
      {notesEditable ? (
        <>
          <TextField
            id="outlined-multiline-static"
            label={customLabel ? customLabel : t("notesLabel")}
            multiline
            rows={5}
            defaultValue={notes}
            variant="outlined"
            onChange={(event) => setNotes(event.target.value)}
            fullWidth
          />
          <IconButton
            aria-label="clear"
            className={classes.doneButton}
            onClick={() => {
              setNotesEditable(false);
              setNotes(props.notes);
            }}
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            aria-label="save"
            onClick={saveNotes}
            className={classes.doneButton}
          >
            <DoneIcon />
          </IconButton>
        </>
      ) : (
        <>
          {!readonly && (
            <IconButton
              aria-label="edit"
              className={classes.editButton}
              onClick={() =>
                notesEditable ? setNotesEditable(false) : setNotesEditable(true)
              }
            >
              <EditIcon />
            </IconButton>
          )}
          <Typography variant="h6">
            {customLabel ? customLabel : t("notesLabel")}
          </Typography>
          <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
            {props.notes}
          </Typography>
        </>
      )}
    </Paper>
  );
};

export default withStyles(styles)(EditableNotes);
