import React, { useEffect, useState, useRef } from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  ButtonGroup,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField
} from "@material-ui/core";
import FeatureHistory from "./maps/FeatureHistory";
import {
  ensureMapScript,
  fitToBounds,
  containsPolygon,
  stylePoint,
  loadGeoJson,
  styleFeatures,
  updateArrows,
  removeVertexFromPolygon,
  removeVertexFromLineString,
  calculateSquareMeters,
  drawPolygonInside,
  removeEditableFeature,
  newFeature,
  getFeaturesBounds
} from "./maps/MapHelpers";
import createImage from "./maps/createImage";
import {
  FeatureCollection,
  SquareMeters,
  ProspectForm,
  RouteSegment
} from "../../../redux/types";
import {
  Undo as UndoIcon,
  Redo as RedoIcon,
  Save as SaveIcon,
  CenterFocusWeak as CenterFocusWeakIcon,
  Delete as DeleteIcon,
  CallMade as CallMadeIcon,
  EditLocation as EditLocationIcon,
  FileCopy as CopyIcon
} from "@material-ui/icons";
import { useTranslate } from "../../../services/appLanguageService";
import { FieldArrayRenderProps, FormikProps } from "formik";
import clsx from "clsx";
import { ErrorBoundary } from "../../ErrorBoundary";
import { showGlobalSnackbar } from "../../../helpers/globalHelper";
import geojsonValidation from "geojson-validation";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2.5),
      marginTop: theme.spacing(2.5)
    },
    dialogPaper: {
      marginTop: 0,
      padding: `0 ${theme.spacing(2.5)}px`
    },
    buttonGroup: {
      marginBottom: theme.spacing(2.5)
    },
    lowercase: {
      textTransform: "none",
      marginBottom: 2
    },
    clipboardGroup: {
      display: "inline-flex",
      marginLeft: theme.spacing(2.0),
      "& > div:last-child": {
        justifyContent: "center",
        alignSelf: "center",
        marginLeft: theme.spacing(2)
      }
    }
  });

interface MapProps extends WithStyles<typeof styles> {
  geo_polygons: FeatureCollection | null;
  resourceId?: number;
  editable: boolean;
  handleSave?: (
    resourceId: number,
    geo_polygons: FeatureCollection | null,
    squareMeters?: SquareMeters,
    prospectForm?: ProspectForm
  ) => void;
  handleCreateImage?: (resourceId: number, image: string) => void;
  isInDialog?: boolean;
  formikProps?: FormikProps<any>;
  mapDialogData?: {
    row: RouteSegment | null;
    open: boolean;
    arrHelpers: FieldArrayRenderProps | null;
    index: number | null;
  };
  forDistrict?: boolean;
  saveEvent?: boolean;
  showSaveButton?: boolean;
  className?: string;
  defaultLongitude?: number;
  defaultLatitude?: number;
}

const IMAGE_RATIO = 1.5;

const Map: React.FC<MapProps> = (props) => {
  const { classes, mapDialogData, className } = props;
  const t = useTranslate("MapComponent");

  const mapRef = useRef<google.maps.Map | null>(null);

  const [geoJsonLoaded, setGeoJsonLoaded] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const pasteField = useRef<HTMLInputElement>(null);

  const currentGroupRef = useRef("");
  const [featureHistory, setFeatureHistory] = useState<
    FeatureHistory | undefined
  >(undefined);
  const drawingInterruptedRef = useRef(false);

  const markerRef = useRef<google.maps.Marker | null>(null);
  const [markerChecked, setMarkerChecked] = useState<boolean>(true);

  const [mapHeight, setMapHeight] = useState<number>(window.innerHeight * 0.7);

  useEffect(() => {
    if (node.current === null) {
      return;
    }

    const resizeObserver = new ResizeObserver(() =>
      setMapHeight(window.innerHeight * 0.7)
    );

    resizeObserver.observe(document.body);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [node.current]);

  const [squareMeters, setSquareMeters] = useState<SquareMeters | undefined>({
    area: 0,
    dumpsite: 0,
    priority: 0,
    manual: 0
  });

  const initMap = () => {
    if (!mapRef.current) {
      ensureMapScript()
        .then(createGoogleMap)
        .catch((error) => console.log("Error in initializing map: ", error));
    }
  };

  const createGoogleMap = () => {
    if (window.google && node.current !== null) {
      const googleMap: google.maps.Map = new window.google.maps.Map(
        node.current,
        {
          zoom: 12,
          center: {
            lat: 57.7,
            lng: 11.96
          },
          mapTypeId: "hybrid",
          rotateControl: false,
          gestureHandling: props.editable ? "greedy" : "cooperative"
        }
      );
      mapRef.current = googleMap;
      setFeatureHistory(new FeatureHistory(currentGroupRef, googleMap));

      if (
        googleMap &&
        props.defaultLongitude &&
        props.defaultLatitude &&
        props.editable
      ) {
        markerRef.current = new google.maps.Marker({
          position: { lat: props.defaultLatitude, lng: props.defaultLongitude },
          map: mapRef.current,
          title: "Address"
        });

        setMarkerChecked(true);
      }
    }
  };

  /**
   * Load the geographic objects from the database (stored as geojson) into the map
   *
   */
  const initGeoJson = () => {
    if (
      mapRef.current &&
      props.geo_polygons &&
      !geoJsonLoaded &&
      featureHistory
    ) {
      setGeoJsonLoaded(true);
      loadGeoJson(mapRef.current, props.geo_polygons);
      fitToBounds(mapRef.current);
      initEventListeners();
      setSquareMeters(calculateSquareMeters(mapRef.current));
    }
  };

  const reloadGeoJson = (geojson = props.geo_polygons) => {
    if (mapRef.current && geojson && geoJsonLoaded) {
      mapRef.current.data.forEach((feature) => {
        mapRef.current?.data.remove(feature);
      });
      loadGeoJson(mapRef.current, geojson);
      fitToBounds(mapRef.current);
      initEventListeners();
      setSquareMeters(calculateSquareMeters(mapRef.current));
    }
  };

  const finalizeIfNoGeoJSON = () => {
    if (mapRef.current && !props.geo_polygons) {
      initEventListeners();
      setSquareMeters(calculateSquareMeters(mapRef.current));
    }
  };

  const saveGeoJson = () => {
    if (mapRef.current) {
      mapRef.current.data.toGeoJson((object) => {
        let geojson: FeatureCollection = object as FeatureCollection;
        const valid = geojsonValidation.isGeoJSONObject(geojson);
        if (!valid) {
          const validFeatures = geojson.features.filter((f) =>
            geojsonValidation.isFeature(f)
          );
          geojson = { type: "FeatureCollection", features: validFeatures };
          showGlobalSnackbar(t("invalidGeoJsonInfoMessage"), "info");
          reloadGeoJson(geojson);
        }
        // TODO: Fix map image
        if (
          mapRef.current &&
          props.resourceId &&
          props.handleCreateImage &&
          node.current
        ) {
          createImage(
            mapRef.current,
            props.resourceId,
            props.handleCreateImage,
            node.current,
            () => {
              if (markerRef.current?.getVisible()) {
                markerRef.current.setVisible(false);
                setMarkerChecked(false);
              }
            },
            () => {
              if (markerRef.current && !markerRef.current.getVisible()) {
                markerRef.current.setVisible(true);
                setMarkerChecked(true);
              }
            }
          );
        }

        if (props.formikProps) {
          setGeoJsonLoaded(true);
          props.formikProps.setFieldValue("geo_polygons", geojson);
        }

        if (props.resourceId && props.handleSave) {
          setGeoJsonLoaded(true);
          props.handleSave(
            props.resourceId,
            geojson,
            squareMeters || { area: 0, dumpsite: 0, priority: 0, manual: 0 }
          );
        }
      });
    }
  };

  const removeFeature = () => {
    if (mapRef.current && featureHistory) {
      // If clicked while drawing, interrupt the drawing and
      // remove the feature that is created
      // (Flag is set here, setDrawingMode(null) triggers the addfeature eventlistener,
      // which sets the feature as an editable. All editables are then removed further down in
      // this function)
      if (
        mapRef.current.data.getDrawingMode() !== undefined &&
        mapRef.current.data.getDrawingMode() !== null
      ) {
        drawingInterruptedRef.current = true;
        mapRef.current.data.setDrawingMode(null);
      }

      let featureDeleted = false;
      mapRef.current.data.forEach((feature) => {
        if (feature.getProperty("editable") === true) {
          // A feature is only saved in featureHistory if it was not interrupted while drawing
          if (!drawingInterruptedRef.current) {
            featureHistory.addPost({
              feature: feature,
              oldGeometry: feature.getGeometry(),
              newGeometry: feature.getGeometry()
            });
          } else {
            drawingInterruptedRef.current = false;
          }
          mapRef.current?.data.remove(feature);
          featureDeleted = true;
        }
      });

      if (featureDeleted) {
        updateArrows(mapRef.current);
        setSquareMeters(calculateSquareMeters(mapRef.current));
        if (props.formikProps) {
          saveGeoJson();
        }
      }
    }
  };

  const writeToClipboardGeoJson = () =>
    new Promise<void>((resolve, reject) => {
      if (!navigator.clipboard) {
        reject(Error("Clipboard interface missing"));
      }
      if (mapRef.current) {
        mapRef.current.data.toGeoJson((o) => {
          navigator.clipboard.writeText(JSON.stringify(o));
          resolve();
        });
      } else {
        reject(Error("Map instance not loaded"));
      }
    }).then(() => showGlobalSnackbar(t("copySuccessMessage"), "success"));

  useEffect(() => {
    const onPaste = (e: ClipboardEvent) => {
      if (mapRef.current) {
        const text = e.clipboardData?.getData("text/plain");
        if (text) {
          const geoJson = JSON.parse(text);
          if (
            "type" in geoJson &&
            geoJson.type === "FeatureCollection" &&
            "features" in geoJson &&
            "length" in geoJson.features
          ) {
            mapRef.current.data.forEach((feature) =>
              mapRef.current?.data.remove(feature)
            );
            loadGeoJson(mapRef.current, geoJson);
          }
          const bounds = new google.maps.LatLngBounds();
          mapRef.current.data.forEach((f) =>
            f.getGeometry().forEachLatLng((p) => bounds.extend(p))
          );
          mapRef.current.fitBounds(bounds);
          showGlobalSnackbar(t("pasteSuccessMessage"), "success");
        }
      }
    };
    const element = pasteField.current;
    if (element && mapRef.current) {
      element.addEventListener("paste", onPaste);
      return () => {
        element.removeEventListener("paste", onPaste);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current, node.current]);

  const initEventListeners = () => {
    if (mapRef.current && featureHistory) {
      if (props.editable) {
        mapRef.current.data.addListener("addfeature", (event) => {
          let feature: google.maps.Data.Feature = event.feature;

          //there are cases when Features are added that wont result in valid geojson objects
          feature.toGeoJson((object) => {
            if (!geojsonValidation.isGeoJSONObject(object)) {
              mapRef.current?.data.remove(feature);
            }
          });

          // If drawing is interrupted by clicking the remove-button, the feature is set to editable
          // (since all editable features are removed)
          if (drawingInterruptedRef.current) {
            mapRef.current?.data.forEach((currentFeature) => {
              if (event.feature === currentFeature) {
                currentFeature.setProperty("editable", true);
              }
            });
            return;
          }

          let removeFeatureInside = false;
          if (feature.getProperty("group") !== "directionArrow") {
            feature.setProperty("editable", false);
            if (!feature.getProperty("group")) {
              feature.setProperty("group", currentGroupRef.current);
            }

            if (mapRef.current && currentGroupRef.current === "direction") {
              currentGroupRef.current = "";
              updateArrows(mapRef.current);
            }

            if (
              mapRef.current &&
              (currentGroupRef.current === "entrance" ||
                currentGroupRef.current === "exit")
            ) {
              updateArrows(mapRef.current);
              stylePoint(mapRef.current, feature);
            }

            mapRef.current?.data.setDrawingMode(null);
            mapRef.current?.data.overrideStyle(feature, { editable: false });

            // Check if any polygon has been drawed inside another polygon,
            // if so, create a 'hole' in the outer polygon
            mapRef.current?.data.forEach((currentFeature) => {
              if (
                mapRef.current &&
                currentFeature !== feature &&
                currentFeature.getGeometry().getType() === "Polygon" &&
                containsPolygon(currentFeature, feature) &&
                currentFeature.getProperty("group") ===
                  feature.getProperty("group")
              ) {
                drawPolygonInside(currentFeature, feature);
                removeFeatureInside = true;
                setSquareMeters(calculateSquareMeters(mapRef.current));
              }
            });

            if (removeFeatureInside) {
              mapRef.current?.data.forEach(function (
                currentFeature: google.maps.Data.Feature
              ) {
                if (currentFeature === feature) {
                  currentFeature.setProperty("remove", true);
                }
              });
            }
          }
          if (mapRef.current) {
            setSquareMeters(calculateSquareMeters(mapRef.current));
          }
          if (props.formikProps) {
            saveGeoJson();
          }
        });

        mapRef.current.data.addListener("setgeometry", (event) => {
          if (mapRef.current) {
            featureHistory.addPost(event);
            setSquareMeters(calculateSquareMeters(mapRef.current));
            if (props.formikProps) {
              saveGeoJson();
            }
          }
        });

        // When clicking on a feature, set to editable and draggable
        mapRef.current.data.addListener("click", (event) => {
          handleClickEvent(event, "leftClick");
        });

        mapRef.current.addListener("click", (event) => {
          handleClickEvent(event, "leftClick");
        });

        // When clicking on a feature, set to editable and draggable
        mapRef.current.data.addListener("rightclick", (event) => {
          handleClickEvent(event, "rightclick");
        });

        const handleClickEvent = (event: any, clickType: string) => {
          let feature = event.feature,
            vertexRemoved = false;

          if (feature === undefined) {
            mapRef.current?.data.forEach(function (currentFeature) {
              if (
                mapRef.current &&
                currentFeature.getProperty("editable") === true
              ) {
                removeEditableFeature(mapRef.current, currentFeature);
              }
            });
          } else {
            if (feature.getProperty("editable") === false) {
              //prevent scrolling on touch devices, in order to move objects
              // $container.find('#map_setup').on('touchmove', disableDefaultEvent);

              feature.setProperty("editable", true);
              // removeAllArrows(mapRef.current);

              mapRef.current?.data.overrideStyle(feature, {
                editable: true,
                draggable: clickType === "rightclick"
              });

              // If already editable and draggable
            } else if (
              mapRef.current &&
              feature.getProperty("editable") === true
            ) {
              // $container.find('#map_setup').off('touchmove', disableDefaultEvent);

              // Checks if a vertex has been clicked
              if (feature.getGeometry().getType() === "Polygon") {
                vertexRemoved = removeVertexFromPolygon(
                  mapRef.current,
                  feature,
                  event.latLng
                );
              } else if (feature.getGeometry().getType() === "LineString") {
                vertexRemoved = removeVertexFromLineString(
                  mapRef.current,
                  feature,
                  event.latLng
                );
              }

              if (!vertexRemoved) {
                // featureHistory.clearForFeature(feature);
                removeEditableFeature(mapRef.current, feature);
              }
              setSquareMeters(calculateSquareMeters(mapRef.current));
            }
          }
        };

        // Thicker stroke when mouse over polygon or line
        mapRef.current.data.addListener("mouseover", (event) => {
          mapRef.current?.data.overrideStyle(event.feature, {
            strokeWeight: 4
          });

          // Removal of polygon is made here, because of bug in google maps
          mapRef.current?.data.forEach(function (currentFeature) {
            if (currentFeature.getProperty("remove") === true) {
              mapRef.current?.data.remove(currentFeature);
              if (props.formikProps) {
                saveGeoJson();
              }
            }
          });
        });

        // Thinner stroke when mouse not over polygon or line
        mapRef.current.data.addListener("mouseout", (event) => {
          mapRef.current?.data.overrideStyle(event.feature, {
            strokeWeight: 2
          });
        });
      }
    }
  };

  useEffect(() => {
    if (
      mapRef.current &&
      typeof props.saveEvent !== "undefined" &&
      props.saveEvent &&
      mapDialogData &&
      mapDialogData.arrHelpers &&
      mapDialogData.index !== null &&
      mapDialogData.row
    ) {
      const rowIndex = mapDialogData.index;
      const arrayHelpers = mapDialogData.arrHelpers;

      mapRef.current.data.toGeoJson((object) => {
        arrayHelpers.replace(rowIndex, {
          ...mapDialogData.row,
          geo_polygons: object as FeatureCollection
        });
      });
    }
  }, [props.saveEvent, mapDialogData]);

  useEffect(() => {
    if (mapRef.current && props.defaultLatitude && props.defaultLongitude) {
      let numberOfFeatures = 0;

      mapRef.current.data.forEach((feature) => (numberOfFeatures += 1));

      if (numberOfFeatures === 0) {
        mapRef.current.setCenter({
          lat: props.defaultLatitude,
          lng: props.defaultLongitude
        });
        mapRef.current.setZoom(16);
      }
    }
  }, [props.defaultLatitude, props.defaultLongitude]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initMap, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initGeoJson, [props.geo_polygons, mapRef.current, featureHistory]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reloadGeoJson, [props.resourceId]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(finalizeIfNoGeoJSON, [mapRef.current, featureHistory]);

  return (
    <Paper
      className={clsx(
        props.isInDialog ? classes.dialogPaper : classes.paper,
        className
      )}
    >
      {props.editable && (
        <>
          <div>
            {!props.forDistrict ? (
              <ButtonGroup
                className={classes.buttonGroup}
                variant="outlined"
                color="primary"
                aria-label="small outlined button group"
              >
                <Button
                  onClick={() =>
                    mapRef.current &&
                    newFeature("area", mapRef.current, currentGroupRef)
                  }
                >
                  <EditLocationIcon style={{ color: "#2196f3" }} />
                  {`${t("areaLabel")} ${squareMeters ? squareMeters.area : 0}`}
                  <span className={classes.lowercase}>
                    &nbsp;m<sup>2</sup>
                  </span>
                </Button>
                <Button
                  onClick={() =>
                    mapRef.current &&
                    newFeature("dumpsite", mapRef.current, currentGroupRef)
                  }
                >
                  <EditLocationIcon style={{ color: "#e91e63" }} />
                  {`${t("dumpsiteLabel")} ${
                    squareMeters ? squareMeters.dumpsite : 0
                  }`}
                  <span className={classes.lowercase}>
                    &nbsp;m<sup>2</sup>
                  </span>
                </Button>
                <Button
                  onClick={() =>
                    mapRef.current &&
                    newFeature("priority", mapRef.current, currentGroupRef)
                  }
                >
                  <EditLocationIcon style={{ color: "#ff5722" }} />
                  {`${t("priorityLabel")} ${
                    squareMeters ? squareMeters.priority : 0
                  }`}
                  <span className={classes.lowercase}>
                    &nbsp;m<sup>2</sup>
                  </span>
                </Button>
                <Button
                  onClick={() =>
                    mapRef.current &&
                    newFeature("manual", mapRef.current, currentGroupRef)
                  }
                >
                  <EditLocationIcon style={{ color: "#4caf50" }} />
                  {`${t("manualLabel")} ${
                    squareMeters ? squareMeters.manual : 0
                  }`}
                  <span className={classes.lowercase}>
                    &nbsp;m<sup>2</sup>
                  </span>
                </Button>
                <Button
                  onClick={() =>
                    mapRef.current &&
                    newFeature("building", mapRef.current, currentGroupRef)
                  }
                >
                  <EditLocationIcon style={{ color: "#b2b2b2" }} />
                  {t("buildingLabel")}
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup
                className={classes.buttonGroup}
                variant="outlined"
                color="primary"
                aria-label="small outlined button group"
              >
                <Button
                  onClick={() =>
                    mapRef.current &&
                    newFeature("area", mapRef.current, currentGroupRef)
                  }
                  data-cy="draw-district"
                >
                  {`${t("drawDistrictLabel")} ${
                    squareMeters
                      ? Math.round(
                          (squareMeters.area / 1000000) * 100 + Number.EPSILON
                        ) / 100
                      : 0
                  }`}
                  <span className={classes.lowercase}>
                    &nbsp;km<sup>2</sup>
                  </span>
                </Button>
                <Button onClick={() => removeFeature()}>
                  <DeleteIcon />
                  {t("removeLabel")}
                </Button>
                <Button
                  onClick={() => {
                    if (mapRef.current) {
                      fitToBounds(mapRef.current);
                    }
                  }}
                >
                  <CenterFocusWeakIcon />
                  {t("centerLabel")}
                </Button>
                <Button
                  onClick={() => {
                    if (featureHistory && mapRef.current) {
                      featureHistory.moveBackward();
                    }
                  }}
                >
                  <UndoIcon />
                  {t("undoLabel")}
                </Button>
                <Button
                  onClick={() => {
                    if (featureHistory && mapRef.current) {
                      featureHistory.moveForward();
                    }
                  }}
                >
                  <RedoIcon />
                  {t("redoLabel")}
                </Button>
              </ButtonGroup>
            )}

            {!props.forDistrict && (
              <>
                <ButtonGroup
                  className={classes.buttonGroup}
                  variant="outlined"
                  color="primary"
                  aria-label="small outlined button group"
                >
                  <Button
                    onClick={() =>
                      mapRef.current &&
                      newFeature("entrance", mapRef.current, currentGroupRef)
                    }
                  >
                    <CallMadeIcon style={{ color: "#4caf50" }} />
                    {t("entranceLabel")}
                  </Button>
                  <Button
                    onClick={() =>
                      mapRef.current &&
                      newFeature("exit", mapRef.current, currentGroupRef)
                    }
                  >
                    <CallMadeIcon style={{ color: "#ff5722" }} />
                    {t("exitLabel")}
                  </Button>
                  <Button
                    onClick={() =>
                      mapRef.current &&
                      newFeature("direction", mapRef.current, currentGroupRef)
                    }
                  >
                    <CallMadeIcon style={{ color: "#ffc107" }} />
                    {t("directionLabel")}
                  </Button>
                  <Button onClick={() => removeFeature()}>
                    <DeleteIcon />
                    {t("removeLabel")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (mapRef.current) {
                        fitToBounds(mapRef.current);
                      }
                    }}
                  >
                    <CenterFocusWeakIcon />
                    {t("centerLabel")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (featureHistory && mapRef.current) {
                        featureHistory.moveBackward();
                        styleFeatures(mapRef.current);
                      }
                    }}
                  >
                    <UndoIcon />
                    {t("undoLabel")}
                  </Button>
                  <Button
                    onClick={() => {
                      if (featureHistory && mapRef.current) {
                        featureHistory.moveForward();
                      }
                    }}
                  >
                    <RedoIcon />
                    {t("redoLabel")}
                  </Button>
                  {typeof props.showSaveButton !== "undefined" &&
                  !props.showSaveButton ? (
                    <></>
                  ) : (
                    <Button
                      onClick={() => {
                        if (mapRef.current) {
                          let bounds = getFeaturesBounds(
                            mapRef.current,
                            new google.maps.LatLngBounds()
                          );
                          if (bounds.isEmpty()) {
                            bounds =
                              mapRef.current.getBounds() as google.maps.LatLngBounds;
                          }
                          const center = bounds.getCenter();
                          mapRef.current.setCenter(
                            new google.maps.LatLng(
                              center.lat() + 0.001,
                              center.lng() + 0.001
                            )
                          );
                          mapRef.current.addListener("idle", () => {
                            if (mapRef.current) {
                              google.maps.event.clearListeners(
                                mapRef.current,
                                "idle"
                              );
                            }
                            saveGeoJson();
                          });
                          mapRef.current.fitBounds(bounds);
                        }
                      }}
                    >
                      <SaveIcon />
                      {t("saveLabel")}
                    </Button>
                  )}
                </ButtonGroup>
                <div className={classes.clipboardGroup}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => writeToClipboardGeoJson()}
                  >
                    <CopyIcon />
                    {t("copyLabel")}
                  </Button>
                  <div>
                    <TextField
                      ref={pasteField}
                      value=""
                      placeholder={t("pasteHelpText")}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {markerRef.current && mapRef.current && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={markerChecked}
                  onClick={() => {
                    if (markerRef.current?.getVisible()) {
                      setMarkerChecked(false);
                      markerRef.current.setVisible(false);
                    } else {
                      setMarkerChecked(true);
                      markerRef.current?.setVisible(true);
                    }
                  }}
                />
              }
              label={t("showMarkerLabel")}
            />
          )}
        </>
      )}

      <div
        ref={node}
        data-cy="google-map-draw"
        style={{
          height: props.editable ? `${mapHeight}px` : "600px",
          width: props.editable ? `${mapHeight * IMAGE_RATIO}px` : "100%"
        }}
      />
      {props.editable && (
        <Typography variant="caption">{t("rightClickLabel")}</Typography>
      )}
    </Paper>
  );
};

const ErrorMessage: React.FC<{}> = (props) => {
  const t = useTranslate("MapComponent");

  return (
    <Paper style={{ marginTop: 20, padding: 20 }}>
      <Typography variant="h6">{t("errorText")}</Typography>
    </Paper>
  );
};

export default withStyles(styles)((props: MapProps) => (
  <ErrorBoundary errorMessageComponent={<ErrorMessage />}>
    <Map {...props} />
  </ErrorBoundary>
));
