import { useState } from "react";
import {
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  WithStyles,
  withStyles,
  Button,
  CircularProgress
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import createStyles from "@material-ui/core/styles/createStyles";
import { Field, FieldArrayRenderProps, FieldProps, FormikProps } from "formik";
import {
  FeatureCollection,
  RouteInstance,
  RouteInstanceForm,
  RouteInstanceProgress,
  RouteParticipantForm,
  RoutePlan,
  RouteSegment,
  SelectFieldOption
} from "../../../redux/types";
import { getTaskAPI } from "../../../services/api-declaration";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import { DraggableProvided } from "react-beautiful-dnd";
import {
  Delete as DeleteIcon,
  Map as MapIcon,
  DragHandle as DragIcon
} from "@material-ui/icons";
import { useTranslate } from "../../../services/appLanguageService";
import { BlurTextField } from "./BlurTextField";
import { validateApiUsage } from "../../../helpers/routesHelper";
import { getFromSessionStore } from "../../../services/storageService";
import clsx from "clsx";

const styles = (theme: any) =>
  createStyles({
    hideElement: {
      display: "none"
    },
    participantCell: {
      paddingRight: 0,
      paddingLeft: theme.spacing(1)
    },
    participantsContainer: {
      display: "flex",
      flexWrap: "wrap"
    },
    toggleButtonGroup: { backgroundColor: "transparent" },
    toggleBtn: {
      width: 40,
      borderRadius: "50%",
      transition: "all 500ms cubic-bezier(0.25, 0.8, 0.25, 1)"
    },
    default: {
      color: "#000",
      margin: "0 -2px",
      transform: "scale(0.8)",
      backgroundColor: "rgba(0, 0, 0, 0.03) !important",
      "&:hover": {
        color: "#fff",
        transform: "scale(1.05)",
        backgroundColor: "rgba(64, 86, 181, 0.5) !important"
      }
    },
    active: {
      transform: "scale(1)",
      color: "#000 !important",
      margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
        0.5
      )}px`
    },
    dragHandleContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    enabled: { pointerEvents: "all" },
    disabled: { pointerEvents: "none" },
    defaultCursor: { cursor: "default" },
    dispatcheDisable: {
      backgroundColor: "#edf5f7"
    },
    dispatcheEnable: {
      backgroundColor: "#F5F5F5"
    },
    dispatchError: {
      backgroundColor: "#FDECEA",
      border: "2px solid #f00"
    },
    DONE: {
      border: "2px solid #00c853",
      backgroundColor: "rgba(90, 201, 85, 0.1) !important",
      "&:hover": {
        backgroundColor: "#a5d6a7 !important"
      }
    },
    STARTED: {
      border: "2px solid #ffeb3b",
      backgroundColor: "rgba(255, 235, 59, 0.1) !important",
      "&:hover": {
        backgroundColor: "#fff59d !important"
      }
    },
    NOT_STARTED: {
      border: "2px solid #ff5252",
      backgroundColor: "rgba(255, 82, 82, 0.1) !important",
      "&:hover": {
        backgroundColor: "#ef9a9a !important"
      }
    },
    iconBtnPadding: {
      padding: theme.spacing(1),
      marginTop: -theme.spacing(1),
      alignContent: "flex-start",
      justifyContent: "flex-start",
      aspectRatio: "square"
    },
    containsCustomGeopolygons: {
      backgroundColor: "#ffffcc"
    }
  });

interface RouteSegmentsTableRowProps extends WithStyles<typeof styles> {
  route?: RouteInstance | RoutePlan;
  formikProps: FormikProps<RouteInstanceForm>;
  readOnlyMode: boolean;
  isDispatched: boolean;
  provided: DraggableProvided;
  index: number;
  segmentRowIds: number[] | undefined;
  isMap: boolean;
  arrayHelpers: FieldArrayRenderProps;
  // segmentOrder: { [key: number]: number };
  onHoverRowIn: (id: number) => () => void;
  onHoverRowOut: (id: number) => () => void;
  rowStatus: RouteInstanceProgress | undefined;
  contractorCompanies: SelectFieldOption[];
  row: RouteSegment;
  showDialogWithData: (
    rowId: number,
    index: number,
    arrHelpers: FieldArrayRenderProps
  ) => () => void;
  toggleDrag: () => void;
  handleMapDialog: (
    row: RouteSegment,
    geo_polygons: FeatureCollection | null,
    arrayHelpers: FieldArrayRenderProps,
    index: number
  ) => void;
  taskOptions: { value: number; label: string; businessAreaName: string }[];
  serviceCategoryOptions: SelectFieldOption[];
  contractorTaskIdsLookup: (companyId: number) => Set<number>;
  addCooperation: (companyId: number, taskId: number) => Promise<void>;
  error: boolean;
}

type PropsHash = Array<[number, SelectFieldOption]>;

const RouteSegmentsTableRow: React.FC<RouteSegmentsTableRowProps> = (props) => {
  const {
    classes,
    formikProps,
    readOnlyMode,
    isDispatched,
    provided,
    route,
    index,
    segmentRowIds,
    isMap,
    arrayHelpers,
    onHoverRowIn,
    onHoverRowOut,
    rowStatus,
    contractorCompanies,
    row,
    showDialogWithData,
    toggleDrag,
    handleMapDialog,
    taskOptions,
    serviceCategoryOptions,
    contractorTaskIdsLookup,
    addCooperation
  } = props;

  const t = useTranslate("NewRouteInstancePage");
  const r = useTranslate("RouteParticipantsTooltipOptions");
  const [fixCooperationsLoading, setFixCooperationsLoading] = useState(false);
  const [selectedTaskGeopolygons, setSelectedTaskGeopolygons] =
    useState<FeatureCollection | null>(null);

  const contractorPairs: PropsHash = contractorCompanies.length
    ? contractorCompanies.map((t) => [t.value, t.label])
    : [];
  const contractorDict = new Map<number, SelectFieldOption>(contractorPairs);

  const tooltipTitleContent = (
    _participant: RouteParticipantForm,
    index: number,
    status: string
  ) => {
    const values: RouteParticipantForm[] = formikProps.values.participants;
    return (
      <>
        <div>
          <b>{r("contractor")}: </b>
          {contractorDict.get(_participant.contractor)}
        </div>
        {status && (
          <div>
            <b>{r("participantStatus")}: </b>
            {status}
          </div>
        )}
        {values[index].instructions && (
          <div>
            <b>{r("instructions")}: </b>
            {values[index].instructions}
          </div>
        )}
      </>
    );
  };

  const participantStatusClass = (status: string) => {
    let statusClass;
    switch (status) {
      case "DONE":
        statusClass = classes.DONE;
        break;
      case "STARTED":
        statusClass = classes.STARTED;
        break;
      case "NOT_STARTED":
        statusClass = classes.NOT_STARTED;
        break;
    }
    return statusClass;
  };

  const loadGeoJsonFromTask = async (
    arrHelpers: FieldArrayRenderProps,
    taskId: number,
    row: RouteSegment,
    index: number
  ) => {
    const response = await getTaskAPI(taskId);
    arrHelpers.replace(index, {
      ...row,
      task: taskId
    });
    setSelectedTaskGeopolygons(response.geo_polygons);
  };

  const task = taskOptions.find(
    (task) => formikProps.values.rows[index].task === task.value
  );

  const hasCooperations = formikProps.values.rows[index].participants
    .map((i) => formikProps.values.participants[i]?.contractor)
    .filter((companyId): companyId is number => companyId !== undefined)
    .map(
      (companyId) =>
        [
          companyId,
          !task || !!contractorTaskIdsLookup(companyId)?.has(task.value)
        ] as const
    );

  const allowEditCooperation =
    getFromSessionStore("is_superuser") === "true" ||
    validateApiUsage({ apiUsage: ["contracts/cooperations"] });

  const onFixCooperations = async () => {
    if (allowEditCooperation) {
      setFixCooperationsLoading(true);
      for (const [companyId, hasCooperation] of hasCooperations) {
        if (!hasCooperation && task) {
          await addCooperation(companyId, task.value);
        }
      }
      setFixCooperationsLoading(false);
    }
  };

  return (
    <TableRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onMouseEnter={task && onHoverRowIn(task.value)}
      onMouseLeave={task && onHoverRowOut(task.value)}
      id={`routeplan-form-routesegment-${index}`}
      className={`${
        route && route.dispatched
          ? classes.dispatcheDisable
          : props.error
          ? classes.dispatchError
          : classes.dispatcheEnable
      }`}
    >
      <TableCell
        className={`${classes.participantCell} ${
          isMap ? classes.hideElement : ""
        } ${
          readOnlyMode ||
          (isDispatched && segmentRowIds && segmentRowIds.includes(row.row_id))
            ? classes.disabled
            : classes.enabled
        }`}
      >
        <div className={classes.participantsContainer}>
          {formikProps.values.participants.map(
            (participant: RouteParticipantForm, indexParticipants: number) => {
              const participantStatus =
                rowStatus &&
                rowStatus.statuses.find(
                  (item) => item.participant_id === participant.participant_id
                );
              return (
                <Field
                  name={`rows.${index}.participants`}
                  id={`routeplan-form-routesegment-${index}-participant-${indexParticipants}`}
                  key={indexParticipants}
                >
                  {({ field, form }: FieldProps<number[], RoutePlan>) => (
                    <div>
                      <ToggleButtonGroup
                        value={""}
                        size="small"
                        className={`${classes.toggleButtonGroup} ${classes.enabled}`}
                        onChange={
                          readOnlyMode ||
                          (isDispatched &&
                            segmentRowIds &&
                            segmentRowIds.includes(row.row_id))
                            ? () => {}
                            : () => {
                                if (
                                  formikProps.values.rows[
                                    index
                                  ].participants.includes(
                                    participant.participant_id
                                  )
                                ) {
                                  if (
                                    formikProps.values.rows[index].participants
                                      .length > 1
                                  ) {
                                    const nextValue = field.value.filter(
                                      (value: number) =>
                                        value !== participant.participant_id
                                    );
                                    form.setFieldValue(
                                      `rows.${index}.participants`,
                                      nextValue
                                    );
                                  }
                                } else {
                                  const nextValue = field.value.concat(
                                    participant.participant_id
                                  );
                                  form.setFieldValue(
                                    `rows.${index}.participants`,
                                    nextValue
                                  );
                                }
                              }
                        }
                      >
                        <Tooltip
                          title={tooltipTitleContent(
                            participant,
                            indexParticipants,
                            participantStatus ? participantStatus.status : ""
                          )}
                          placement="bottom"
                          arrow
                        >
                          <ToggleButton
                            value={""}
                            className={`${classes.toggleBtn} ${
                              formikProps.values.rows[
                                index
                              ].participants.includes(
                                participant.participant_id
                              )
                                ? classes.active
                                : classes.default
                            } ${readOnlyMode && classes.defaultCursor} ${
                              participantStatus &&
                              participantStatus.status &&
                              participantStatusClass(participantStatus.status)
                            }`}
                            selected={formikProps.values.rows[
                              index
                            ].participants.includes(participant.participant_id)}
                          >
                            {indexParticipants + 1}
                          </ToggleButton>
                        </Tooltip>
                      </ToggleButtonGroup>
                    </div>
                  )}
                </Field>
              );
            }
          )}
        </div>
      </TableCell>
      <TableCell
        className={
          readOnlyMode ||
          (isDispatched && segmentRowIds && segmentRowIds.includes(row.row_id))
            ? classes.disabled
            : classes.enabled
        }
      >
        <Field
          autoFocus
          id={`routeplan-form-routesegment-${index}-task`}
          type="text"
          name={`rows.${index}.task`}
          label={t("taskLabel")}
          placeholder={t("taskLabel")}
          value={task && {
            value: task.value,
            label: `${task.label} (${task.businessAreaName})`
          }}
          disabled={isMap}
          options={taskOptions.map((t) => ({
            value: t.value,
            label: `${t.label} (${t.businessAreaName})`
          }))}
          component={AutoCompleteSelect}
          customHandleChange={(newTaskId: any) => {
            if (newTaskId >= 0 && row.task !== newTaskId) {
              loadGeoJsonFromTask(arrayHelpers, newTaskId, row, index);
            }
          }}
          fullWidth
        />
        {allowEditCooperation &&
          hasCooperations.some(([_, hasCoop]) => !hasCoop) && (
            <Tooltip title={t("missingCooperationTooltip")}>
              <Button
                variant="contained"
                style={{ marginTop: "5px" }}
                startIcon={
                  fixCooperationsLoading ? (
                    <CircularProgress size={18} />
                  ) : undefined
                }
                onClick={onFixCooperations}
              >
                {t("missingCooperationButton")}
              </Button>
            </Tooltip>
          )}
      </TableCell>
      <TableCell
        className={`${isMap ? classes.hideElement : ""} ${
          readOnlyMode ||
          (isDispatched && segmentRowIds && segmentRowIds.includes(row.row_id))
            ? classes.disabled
            : classes.enabled
        }`}
      >
        <Field
          id={`routeplan-form-routesegment-${index}-servicecategories`}
          data-cy="baseservice-form-service-category"
          type="text"
          name={`rows.${index}.servicecategories`}
          placeholder={t("serviceCategoryLabel")}
          TextFieldProps={{
            InputLabelProps: {
              htmlFor: "react-select-single",
              shrink: true
            }
          }}
          options={serviceCategoryOptions}
          component={AutoCompleteSelect}
          customHandleChange={(serviceCategoryId: number) => {
            formikProps.setFieldValue(
              `rows.${index}.servicecategories`,
              serviceCategoryId >= 0 ? [serviceCategoryId] : []
            );
          }}
          margin="normal"
          fullWidth
          isMulti={false}
        />
      </TableCell>
      <TableCell
        className={`${isMap ? classes.hideElement : ""} ${
          readOnlyMode ||
          (isDispatched && segmentRowIds && segmentRowIds.includes(row.row_id))
            ? classes.disabled
            : classes.enabled
        }`}
      >
        <BlurTextField
          key={formikProps.values.routeplan}
          id={`rows[${index}].instructions`}
          name={`rows.${index}.instructions`}
          placeholder={t("instructionsLabel")}
          fullWidth
        />
      </TableCell>
      <TableCell
        className={`${isMap ? classes.hideElement : ""} ${classes.enabled}`}
      >
        {task?.value && (
          <Tooltip title={row.geo_polygon_is_overriden ? t("overriddenTooltip") : ""}>
            <IconButton
              id={`routeplan-form-routesegment-${index}-map`}
              onClick={() =>
                handleMapDialog(
                  row,
                  selectedTaskGeopolygons,
                  arrayHelpers,
                  index
                )
              }
              className={clsx(
                classes.iconBtnPadding,
                row.geo_polygon_is_overriden && classes.containsCustomGeopolygons
              )}
            >
              <MapIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      {!readOnlyMode && (
        <>
          <TableCell
            className={isMap ? classes.hideElement : ""}
            align="center"
          >
            <IconButton
              id={`routeplan-form-routesegment-${index}-remove`}
              color="secondary"
              onClick={showDialogWithData(row.row_id, index, arrayHelpers)}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
          <TableCell>
            <div className={classes.dragHandleContainer}>
              <DragIcon onMouseEnter={toggleDrag} onMouseLeave={toggleDrag} />
            </div>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default withStyles(styles)(RouteSegmentsTableRow);
