import { FC, useState } from "react";
import clsx from "clsx";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Tooltip,
  IconButton,
  CircularProgress,
  TableHead,
  makeStyles,
  createStyles,
  Typography
} from "@material-ui/core";
import {
  IApprovalSummaryDetails,
  IApprovalSummary,
  TimeSheetOptions,
  IApprovalSubmittedRow,
  IDataCarryKey,
  IApprovalCorrectionsKey,
  IApprovalRowEditContext,
  TimesheetRowSetAddon,
  IApprovalDictionaries,
  SummaryViewMode
} from "../../redux/types";
import { useTranslate } from "../../services/appLanguageService";
import { useConfirmationDialog } from "../confirmationDialog/ConfirmationDialogServiceProvider";
import { useTimeSheetApprovalDialog } from "../timesheetApprovalDialog/DialogServiceProvider";
import { bulkPayload } from "../../helpers/timesheetApprovalHelper";
import { showGlobalSnackbar } from "../../helpers/globalHelper";
import { VerificationUpdatedAt } from "../../redux/reducers/verificationCount/action";
import store from "../../redux/store";
import { EditModeContainer } from "../timesheetApprovalDialog/editModeContainer";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import LaunchIcon from "@material-ui/icons/Launch";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import ApprovalTypeSelect from "../routes/routeinstances/ApprovalTypeSelect";
import { bulkApprovalsAPI } from "../../services/api-declaration";
import CorrectionsCarry from "../../helpers/classes/verification/CorrectionsCarry";
import TimesheetRowMaterialAddonComponent from "../timesheet-row-add-material/timesheetRowAddMaterial";
import { Formik } from "formik";
import ApprovalInlinePopup from "./ApprovalInlinePopup";
import DateTimeFragment from "../timesheetApprovalDialog/dateTimeFragment";
import { APPROVAL_EDIT_FIELDS } from "../../strings/WorkApprovalStrings";
import PackageCarry from "../../helpers/classes/verification/PackageCarry";
import { fetchPackageOptionsData } from "../../services/routeInstanceApprovalService";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import {
  differenceInCalendarDays,
  differenceInMinutes,
  format,
  isEqual
} from "date-fns";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    timesheetRowActions: {
      display: "flex",
      justifyContent: "center"
    },
    flex: {
      display: "flex",
      justifyContent: "center"
    },
    approvedRow: {
      color: "#4BB453",
      marginTop: theme.spacing(1.5)
    },
    redRow: {
      "& > *": {
        color: "red"
      }
    },
    root: {
      "& > *": {
        position: "relative",
        borderBottom: "unset"
      }
    },
    alternatingRow: {
      backgroundColor: "#e7e7e7"
    },
    approved: {
      "& td": {
        color: "rgba(0, 0, 0, 0.5)"
      }
    },
    activeGenericRow: {
      backgroundColor: "#bdbdbd",
      transition: "background-color 300ms ease-in-out"
    },
    loadingPaper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minWidth: 240,
      minHeight: 130,
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1
    },
    table: {
      "& tr:nth-child(odd) > td": {
        backgroundColor: "#e7e7e7"
      },
      "& tr.row--day-diff td": {
        backgroundColor: "#bbbbbb",
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(3),
        ...theme.typography.body1
      },
      "& tr.row--missing-time td": {
        backgroundColor: "#bbbbbb",
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(3),
        ...theme.typography.body1,
        color: theme.palette.error.main
      }
    },
    rowHasDocumentation: {
      backgroundColor: "#ffffcc"
    },
    editableCell: {
      cursor: "pointer",
      "& .cell--underline": {
        textDecoration: "underline"
      }
    },
    formControl: {
      width: "100%",
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      display: "flex",
      flexDirection: "row"
    },
    field: { marginTop: theme.spacing(0.5) },
    dateTimeField: {
      marginBottom: theme.spacing(1)
    },
    materialField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    checkboxRow: {
      marginLeft: -theme.spacing(0.5),
      padding: theme.spacing(1),
      marginTop: -theme.spacing(1)
    },
    iconBtnPadding: {
      padding: theme.spacing(1),
      marginTop: -theme.spacing(1),
      alignContent: "flex-start",
      justifyContent: "flex-start",
      aspectRatio: "square"
    },
    inProgress: {
      "& td": {
        color: "rgba(0, 0, 0, 0.5)"
      }
    },
    inProgressIcon: { color: "grey" },
    thRow: {
      "& > *": {
        color: "#000",
        fontWeight: "bold",
        padding: `0 ${theme.spacing(1.5)}px`
      }
    },
    nowrap: {}
  })
);

const formatDuration = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60).toString();
  const minutes = Math.floor(totalMinutes % 60).toString();
  return `${hours}:${minutes.padStart(2, "0")}`;
};

interface ApprovalSubmittedRowsProps {
  summaryDetails: IApprovalSummaryDetails;
  selectedRows: IApprovalSummary["selectedRows"];
  serviceCategories: IApprovalSummary["serviceCategories"];
  allServiceCategories: IApprovalSummary["allServiceCategories"];
  businessAreaNames: Map<number, string>;
  dictionaries: IApprovalDictionaries;
  submitRowDeleted: (routeInstance_id: number) => void;
  submitRowModified: (routeInstance_id: number, submit_id: number) => void;
  updateApprovalSelection: () => void;
  SummaryViewMode: SummaryViewMode;
  toggleSummaryRowDetails?: (row: IApprovalSubmittedRow) => void;
  data: IApprovalSummaryDetails["submittedRows"];
  openDocumentationPopup: (routeinstance_id: number, submit_id: number) => void;
}

const ApprovalSubmittedRows: FC<ApprovalSubmittedRowsProps> = (props) => {
  const {
    summaryDetails: { routeinstance_id, packageOptionsMap, approvableRows },
    data,
    selectedRows,
    dictionaries: [materialDictionary, taskDictionary, timesheetOptionDict],
    submitRowDeleted,
    submitRowModified,
    updateApprovalSelection,
    SummaryViewMode,
    allServiceCategories,
    businessAreaNames,
    openDocumentationPopup
  } = props;
  const classes = useStyles();
  const t = useTranslate("TimesheetApprovalPage");
  const v = useTranslate("TimeSheetRowsAndApprovals");
  const confirmationDialog = useConfirmationDialog();
  const openApprovalDialog = useTimeSheetApprovalDialog();

  const [editRowContext, setEditRowContext] = useState<IApprovalRowEditContext>(
    {
      popupField: "",
      editId: -1,
      correctionsCarry: {} as CorrectionsCarry,
      packageCarry: undefined,
      timesheetOptions: undefined,
      isSaving: false
    }
  );

  const cancelInlineEditMode = () =>
    setEditRowContext({
      popupField: "",
      editId: -1,
      correctionsCarry: {} as IApprovalRowEditContext["correctionsCarry"],
      packageCarry: undefined,
      timesheetOptions: undefined,
      isSaving: false
    });

  const openInlineEdit = (
    field: IApprovalRowEditContext["popupField"],
    row: IApprovalSubmittedRow
  ) => {
    if (row.status === "APPROVED") {
      return;
    }
    if (!row.workApproval) {
      return;
    }
    if (selectedRows.has(row.submit_id)) {
      handleRowSelection(row, selectedRows);
    }
    if (editRowContext?.editId === row._id) {
      setEditRowContext((editContext) => ({
        ...editContext,
        popupField: field
      }));
      return;
    }

    // const { packageOptionsMap } = summaryDetails;
    const timesheetOptions = timesheetOptionDict.get(row.timesheet_id);
    if (!timesheetOptions) {
      timesheetOptionDict.collectId(row.timesheet_id);
      timesheetOptionDict
        .fetchData(materialDictionary)
        .then((timesheetOptions) =>
          setEditRowContext((context) => ({
            ...context,
            timesheetOptions
          }))
        );
    }
    const packageOptions = packageOptionsMap.get(row.submit_id);
    if (!packageOptions) {
      fetchPackageOptionsData(row.submit_id).then((packageOptions) => {
        packageOptionsMap.set(row.submit_id, packageOptions);
        setEditRowContext((context) => ({
          ...context,
          packageCarry: new PackageCarry(
            routeinstance_id,
            row.workApproval!.submittedwork.id,
            row.workApproval!.getPackage(),
            packageOptions
          )
        }));
      });
    }
    setEditRowContext({
      popupField: field,
      editId: row._id,
      correctionsCarry: new CorrectionsCarry(row.workApproval),
      packageCarry:
        packageOptions &&
        row.workApproval &&
        new PackageCarry(
          routeinstance_id,
          row.workApproval.submittedwork.id,
          row.workApproval.getPackage(),
          packageOptions
        ),
      timesheetOptions,
      isSaving: false
    });
  };

  const editModeChanged = (editMode: IDataCarryKey) => {
    setEditRowContext(({ correctionsCarry, ...rest }) => ({
      ...rest,
      correctionsCarry: correctionsCarry.editModeChanged(editMode)
    }));
  };
  const resetField = (fieldName: IApprovalCorrectionsKey) => {
    setEditRowContext(({ correctionsCarry, ...rest }) => ({
      ...rest,
      correctionsCarry: correctionsCarry.resetField(fieldName),
      readables: correctionsCarry.getSubmittedReadables()
    }));
  };

  const correctionChanged = (fieldName: IApprovalCorrectionsKey, value: any) =>
    setEditRowContext(({ correctionsCarry, ...rest }) => ({
      ...rest,
      correctionsCarry: correctionsCarry.correctionChanged(fieldName, value),
      readables: correctionsCarry.getSubmittedReadables()
    }));

  const correctionPopupClosed = () =>
    setEditRowContext((context) => ({ ...context, popupField: "" }));

  const openEditDialog = async (
    { submit_id, workApproval }: IApprovalSubmittedRow,
    options: TimeSheetOptions
  ) => {
    if (workApproval) {
      try {
        await openApprovalDialog(
          {
            open: true,
            maxWidth: "lg",
            children: <></>
          },
          {
            options,
            editableFields: APPROVAL_EDIT_FIELDS,
            defaultCorrectionsCarry: editRowContext.correctionsCarry,
            workApproval,
            packageCarry: editRowContext.packageCarry,
            onClose: (correctionsCarry: CorrectionsCarry) => {
              setEditRowContext((editRowContext) => ({
                ...editRowContext,
                correctionsCarry,
                readables: correctionsCarry.getSubmittedReadables()
              }));
            },
            onSuccess: () => {}
          }
        );
        showGlobalSnackbar(v("updatedSnackbarLabel"), "success");
        submitRowModified(routeinstance_id, submit_id);
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const handleInlineSave = async (
    { submit_id, workApproval }: IApprovalSubmittedRow,
    correctionsCarry: CorrectionsCarry
  ) => {
    if (workApproval) {
      setEditRowContext((context) => ({
        ...context,
        isSaving: true,
        popupField: ""
      }));

      const packageCarry = editRowContext.packageCarry;
      await packageCarry?.savePackages();

      correctionsCarry
        .saveWorkApproval()
        .then((response) => {
          submitRowModified(routeinstance_id, submit_id);
          showGlobalSnackbar(v("updatedSnackbarLabel"), "success");
        })
        .catch(() => {
          setEditRowContext((context) => ({
            ...context,
            isSaving: false
          }));
        });
    }
  };

  const deleteRow = async (submitId: number, routeInstanceId: number) => {
    try {
      await confirmationDialog({
        title: t("deleteReportedRowTitle"),
        description: t("areYouSureLabel")
      });
      const reqBody = bulkPayload([submitId], "unset");
      try {
        await bulkApprovalsAPI(reqBody);
        showGlobalSnackbar(t("rowDeleteLabel"), "success");
        store.dispatch(VerificationUpdatedAt());
        submitRowDeleted(routeInstanceId);
      } catch (e) {
        console.warn(e);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const handleRowSelection = (
    submittedRow: IApprovalSubmittedRow,
    selectedRows: IApprovalSummary["selectedRows"]
  ) => {
    if (!selectedRows.has(submittedRow.submit_id)) {
      selectedRows.set(submittedRow.submit_id, submittedRow);
    } else {
      selectedRows.delete(submittedRow.submit_id);
    }
    updateApprovalSelection();
  };

  const selectAllCheckBox = (
    value: boolean,
    selectedRows: IApprovalSummary["selectedRows"],
    approvableRows: IApprovalSummaryDetails["approvableRows"]
  ) => {
    if (value) {
      approvableRows.forEach((submitted) =>
        selectedRows.set(submitted.submit_id, submitted)
      );
    } else {
      selectedRows.clear();
    }
    updateApprovalSelection();
  };

  const sortedRows = data.flatMap((g) => g.rows);
  sortedRows.sort((a, b) =>
    (a.readables.start_time ?? `zzz ${a.row_id}`).localeCompare(
      b.readables.start_time ?? `zzz ${b.row_id}`
    )
  );
  const routeStartTime = new Date(sortedRows[0]?.readables.start_time);
  let prevStartTime = routeStartTime;
  let prevEndTime = routeStartTime;

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.thRow}>
          {SummaryViewMode === "VERIFICATION" &&
            approvableRows.length !== 0 && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  onChange={(event) =>
                    selectAllCheckBox(
                      event.target.checked,
                      selectedRows,
                      approvableRows
                    )
                  }
                  checked={selectedRows.size === approvableRows.length}
                />
              </TableCell>
            )}
          <TableCell>{t("tableAssignmentHeading")}</TableCell>
          <TableCell>{t("tableEntrepHeading")}</TableCell>
          <TableCell>{t("tableStartHeading")}</TableCell>
          <TableCell>{t("tableStopHeading")}</TableCell>
          <TableCell>{t("totalTimeHeading")}</TableCell>
          <TableCell>{t("materialsLabel")}</TableCell>
          <TableCell>{t("typeCustomerHeading")}</TableCell>
          <TableCell>{t("documentationHeading")}</TableCell>

          {SummaryViewMode === "VERIFICATION" && <TableCell></TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedRows.map((row) => {
          const isEditing = row._id === editRowContext.editId;
          const task = taskDictionary.forDisplay(row.readables.task);
          const isSelectable =
            SummaryViewMode === "VERIFICATION" &&
            row.isApprovable &&
            !isEditing;

          const rowCorrections =
            editRowContext.editId === row._id
              ? editRowContext.correctionsCarry.dataCarry
              : row.workApproval;

          const serviceCategoryId =
            rowCorrections?.corrections?.servicecategory ||
            rowCorrections?.corrections?.reported_service_category ||
            row.servicecategory;
          const serviceCategory = allServiceCategories.find(
            ({ id }) => id === serviceCategoryId
          );

          const contractorStartTime = new Date(
            rowCorrections?.contractor_payables.start_time ??
              rowCorrections?.corrections.start_time ??
              row.start_time
          );
          const customerStartTime = new Date(
            rowCorrections?.customer_billables.start_time ??
              rowCorrections?.corrections.start_time ??
              row.start_time
          );
          const contractorEndTime = new Date(
            rowCorrections?.contractor_payables.end_time ??
              rowCorrections?.corrections.end_time ??
              row.end_time
          );
          const customerEndTime = new Date(
            rowCorrections?.customer_billables.end_time ??
              rowCorrections?.corrections.end_time ??
              row.end_time
          );

          const contractorDuration = differenceInMinutes(
            contractorEndTime,
            contractorStartTime
          );
          const customerDuration = differenceInMinutes(
            customerEndTime,
            customerStartTime
          );

          const showBothStartTimes = !isEqual(
            contractorStartTime,
            customerStartTime
          );
          const showBothEndTimes = !isEqual(contractorEndTime, customerEndTime);
          const showBothDurations = !isEqual(
            contractorDuration,
            customerDuration
          );

          const startDaysDiff = differenceInCalendarDays(
            contractorStartTime,
            prevStartTime
          );
          const endDaysDiff = differenceInCalendarDays(
            contractorEndTime,
            contractorStartTime
          );
          const rowMinuteDiff = differenceInMinutes(
            contractorStartTime,
            prevEndTime
          );

          prevStartTime = contractorStartTime;
          prevEndTime = contractorEndTime;

          return (
            <React.Fragment key={row._id}>
              {startDaysDiff > 0 && (
                <TableRow className="row--day-diff">
                  <TableCell colSpan={12}>
                    + {startDaysDiff} {t("day")}
                  </TableCell>
                </TableRow>
              )}
              {rowMinuteDiff > 0 && (
                <TableRow className="row--missing-time">
                  <TableCell colSpan={12}>
                    + {formatDuration(rowMinuteDiff)}{" "}
                    {t("rowDifferenceContractorWarning")}
                  </TableCell>
                </TableRow>
              )}
              <TableRow
                className={clsx(
                  row.status === "APPROVED" && classes.approved,
                  row.status === "IN_PROGRESS" && classes.inProgress,
                  row.hasConflict &&
                    SummaryViewMode === "VERIFICATION" &&
                    classes.redRow,
                  isEditing && "row--editing"
                )}
              >
                {isSelectable ? (
                  <TableCell
                    className={classes.editableCell}
                    onClick={() => handleRowSelection(row, selectedRows)}
                  >
                    <Checkbox
                      color="primary"
                      className={classes.checkboxRow}
                      checked={selectedRows.has(row.submit_id)}
                    />
                  </TableCell>
                ) : (
                  SummaryViewMode === "VERIFICATION" &&
                  approvableRows.length !== 0 && <TableCell />
                )}

                <TableCell>
                  {task.name}
                  <br />
                  <Typography variant="caption">
                    {businessAreaNames.get(task.businessarea)}
                    {serviceCategory && (
                      <>
                        {" - "}
                        {serviceCategory?.name ?? "---"}
                      </>
                    )}
                  </Typography>
                </TableCell>

                <TableCell>#{row.participant.positionNo}</TableCell>
                <TableCell
                  className={classes.editableCell}
                  onClick={
                    SummaryViewMode === "VERIFICATION" &&
                    editRowContext.popupField !== "start_time"
                      ? () => openInlineEdit("start_time", row)
                      : undefined
                  }
                >
                  {showBothStartTimes && (
                    <div>
                      <span className="cell--underline">
                        {format(contractorStartTime, "HH:mm")}
                      </span>
                      <EditModeContainer
                        dataCarry={rowCorrections}
                        disabledEditMode="customer_billables"
                        fieldName={"start_time"}
                      />
                    </div>
                  )}
                  {customerStartTime && (
                    <div>
                      <span className="cell--underline">
                        {format(customerStartTime, "HH:mm")}
                      </span>
                      <EditModeContainer
                        dataCarry={rowCorrections}
                        disabledEditMode={
                          showBothStartTimes ? "contractor_payables" : undefined
                        }
                        fieldName={"start_time"}
                      />
                    </div>
                  )}

                  <ApprovalInlinePopup
                    fieldName="start_time"
                    correctionsCarry={editRowContext.correctionsCarry}
                    editModeChanged={editModeChanged}
                    resetField={resetField}
                    correctionPopupClosed={correctionPopupClosed}
                    open={
                      isEditing && editRowContext.popupField === "start_time"
                    }
                  >
                    <div className={classes.dateTimeField}>
                      <DateTimeFragment
                        fieldName="start_time"
                        fieldInitials="start"
                        fieldValue={
                          editRowContext.correctionsCarry.corrections
                            ?.start_time
                        }
                        correctionChanged={correctionChanged}
                      />
                    </div>
                  </ApprovalInlinePopup>
                </TableCell>

                <TableCell
                  className={classes.editableCell}
                  onClick={
                    SummaryViewMode === "VERIFICATION" &&
                    editRowContext.popupField !== "end_time"
                      ? () => openInlineEdit("end_time", row)
                      : undefined
                  }
                >
                  {showBothEndTimes && (
                    <div>
                      <span className="cell--underline">
                        {format(contractorEndTime, "HH:mm")}
                      </span>
                      <EditModeContainer
                        dataCarry={rowCorrections}
                        disabledEditMode="customer_billables"
                        fieldName={"end_time"}
                      />
                    </div>
                  )}

                  {customerEndTime && (
                    <div>
                      <span className="cell--underline">
                        {format(customerEndTime, "HH:mm")}
                      </span>
                      <EditModeContainer
                        dataCarry={rowCorrections}
                        disabledEditMode={
                          showBothEndTimes ? "contractor_payables" : undefined
                        }
                        fieldName={"end_time"}
                      />
                    </div>
                  )}

                  {endDaysDiff > 0 && (
                    <Typography variant="caption" component="div">
                      + {endDaysDiff} {t("day")}
                    </Typography>
                  )}

                  <ApprovalInlinePopup
                    fieldName="end_time"
                    correctionsCarry={editRowContext.correctionsCarry}
                    editModeChanged={editModeChanged}
                    resetField={resetField}
                    correctionPopupClosed={correctionPopupClosed}
                    open={isEditing && editRowContext.popupField === "end_time"}
                  >
                    <div className={classes.dateTimeField}>
                      <DateTimeFragment
                        fieldName="end_time"
                        fieldInitials="end"
                        fieldValue={
                          editRowContext.correctionsCarry.corrections?.end_time
                        }
                        correctionChanged={correctionChanged}
                      />
                    </div>
                  </ApprovalInlinePopup>
                </TableCell>

                <TableCell>
                  {showBothDurations && (
                    <div>
                      <span className="cell--underline">
                        {formatDuration(contractorDuration)}
                      </span>
                      <EditModeContainer
                        dataCarry={rowCorrections}
                        disabledEditMode="customer_billables"
                        fieldName={"end_time"}
                      />
                    </div>
                  )}

                  <div>
                    <span className="cell--underline">
                      {formatDuration(customerDuration)}
                    </span>
                    <EditModeContainer
                      dataCarry={rowCorrections}
                      disabledEditMode={
                        showBothEndTimes ? "contractor_payables" : undefined
                      }
                      fieldName={"end_time"}
                    />
                  </div>
                </TableCell>

                <TableCell
                  className={classes.editableCell}
                  onClick={
                    SummaryViewMode === "VERIFICATION" &&
                    editRowContext.popupField !== "materials"
                      ? () => openInlineEdit("materials", row)
                      : undefined
                  }
                >
                  {Object.entries(row.readables.materials ?? {}).map(
                    ([id, { amount }]) => {
                      const materialId = parseInt(id);
                      const { name, unit } =
                        materialDictionary.forDisplay(materialId);
                      return (
                        <div key={materialId} className="cell--underline">
                          {name}: {amount} {unit}
                          <EditModeContainer
                            checker={(editMode, _) => {
                              const reportedAmount =
                                row.materials?.[materialId]?.amount;
                              const amount =
                                row.workApproval?.[editMode].materials?.[
                                  materialId
                                ]?.amount;
                              return (
                                amount !== undefined &&
                                reportedAmount !== amount
                              );
                            }}
                          />
                        </div>
                      );
                    }
                  )}

                  <ApprovalInlinePopup
                    fieldName="materials"
                    correctionsCarry={editRowContext.correctionsCarry}
                    editModeChanged={editModeChanged}
                    resetField={resetField}
                    correctionPopupClosed={correctionPopupClosed}
                    open={
                      isEditing && editRowContext.popupField === "materials"
                    }
                  >
                    <Formik
                      initialValues={
                        editRowContext.correctionsCarry.corrections
                      }
                      onSubmit={() => {}}
                    >
                      <div className={classes.materialField}>
                        {editRowContext.timesheetOptions ? (
                          <TimesheetRowMaterialAddonComponent
                            options={
                              editRowContext.timesheetOptions.contracted
                                ?.materials
                            }
                            fieldName={"materials"}
                            fieldValue={
                              editRowContext.correctionsCarry.corrections
                                ?.materials
                            }
                            correctionChanged={
                              correctionChanged as TimesheetRowSetAddon
                            }
                          />
                        ) : (
                          <CircularProgress />
                        )}
                      </div>
                    </Formik>
                  </ApprovalInlinePopup>
                </TableCell>

                <TableCell
                  className={classes.editableCell}
                  onClick={
                    SummaryViewMode === "VERIFICATION" &&
                    editRowContext.popupField !== "customer"
                      ? () => openInlineEdit("customer", row)
                      : undefined
                  }
                >
                  {!isEditing && row.packageData?.customer && (
                    <span className="cell--underline">
                      {row.packageData.customer.title}
                    </span>
                  )}

                  {!isEditing && !row.packageData?.customer && (
                    <span className="cell--underline">
                      {v("byTheHourOption")}
                    </span>
                  )}

                  {isEditing && editRowContext.packageCarry && (
                    <ApprovalTypeSelect
                      label=""
                      packageType={"customer"}
                      packageCarry={editRowContext.packageCarry}
                      isDisabled={false}
                      onPackageCreated={(_packageCarry) => {}}
                    />
                  )}
                  {isEditing && !editRowContext.packageCarry && (
                    <CircularProgress size={20} />
                  )}
                </TableCell>

                <TableCell align="center">
                  <IconButton
                    onClick={() =>
                      openDocumentationPopup(routeinstance_id, row.submit_id)
                    }
                    className={clsx(
                      classes.iconBtnPadding,
                      !!(row.notes || row.images.length) &&
                        classes.rowHasDocumentation
                    )}
                  >
                    <ImageOutlinedIcon />
                  </IconButton>
                </TableCell>
                {SummaryViewMode === "VERIFICATION" && (
                  <TableCell width="192">
                    <div className={classes.timesheetRowActions}>
                      {row.status === "APPROVED" ? (
                        <Tooltip title={t("approvedRow")} arrow>
                          <DoneAllIcon className={classes.approvedRow} />
                        </Tooltip>
                      ) : row.status === "IN_PROGRESS" ? (
                        <Tooltip title={t("inprogressRow")} arrow>
                          <HourglassEmptyIcon
                            className={classes.inProgressIcon}
                          />
                        </Tooltip>
                      ) : isEditing ? (
                        <>
                          {editRowContext.isSaving ? (
                            <CircularProgress size={20} />
                          ) : (
                            <div>
                              <IconButton
                                onClick={() => {
                                  handleInlineSave(
                                    row,
                                    editRowContext.correctionsCarry
                                  );
                                }}
                                className={classes.iconBtnPadding}
                              >
                                <DoneIcon />
                              </IconButton>
                            </div>
                          )}

                          <div>
                            <IconButton
                              onClick={cancelInlineEditMode}
                              className={classes.iconBtnPadding}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>

                          <div>
                            <IconButton
                              onClick={() =>
                                deleteRow(row.submit_id, routeinstance_id)
                              }
                              className={classes.iconBtnPadding}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>

                          <Tooltip title={t("editInDialogMessage")}>
                            <div>
                              <IconButton
                                onClick={() =>
                                  openEditDialog(
                                    row,
                                    editRowContext.timesheetOptions as TimeSheetOptions
                                  )
                                }
                                className={classes.iconBtnPadding}
                                disabled={!editRowContext.timesheetOptions}
                              >
                                <LaunchIcon />
                              </IconButton>
                            </div>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title={t("editInlineMessage")}>
                          <div>
                            <IconButton
                              onClick={() => openInlineEdit("", row)}
                              className={classes.iconBtnPadding}
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};
export default ApprovalSubmittedRows;
