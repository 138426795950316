import store, { history } from "./redux/store";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import { ConnectedRouter } from "connected-react-router";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";

import svLocale from "date-fns/locale/sv";
import enlocale from "date-fns/locale/en-US";
import { SnackbarProvider } from "notistack";
import { updateConnectionStatus } from "./redux/reducers/connectivity/actions";
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ConnectionEvent } from "./redux/types";
import authenticationService from "./services/authenticationService";
import { displayAppVersionInConsole } from "./services/appVersionService";
import { pdfjs } from "react-pdf";
//@ts-ignore
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://59cac63ab59a8b6f67e8b8591ceaf1b8@o4507215159623680.ingest.de.sentry.io/4508081367351376",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Tracing
    // tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["http://portal.workprecision.com", /^\/api\/v1/],
    tracePropagationTargets: []
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

displayAppVersionInConsole();

authenticationService.loadAppUserAccess();

const handleConnectionChange = (event: Event) => {
  store.dispatch(updateConnectionStatus(event as ConnectionEvent));
};

window.addEventListener("online", handleConnectionChange);
window.addEventListener("offline", handleConnectionChange);

const theme = createTheme({
  palette: {
    primary: {
      main: "#424242"
    }
  }
});

const locale = localStorage.getItem("master_language");
const selectedLocale = locale === "sv-SE" ? svLocale : enlocale;
if (selectedLocale && selectedLocale.options) {
  selectedLocale.options.weekStartsOn = 1;
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={selectedLocale}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={2}>
            <App />
          </SnackbarProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
