import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
  createStyles,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
  Select,
  MenuItem
} from "@material-ui/core";
import {
  Add,
  AddCircle,
  Cancel,
  Clear,
  Delete,
  Done,
  Edit,
  ExpandLess,
  ExpandMore,
  Loop
} from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import * as Yup from "yup";
import { ValidationError } from "yup";
import { useHistory } from "react-router-dom";
import { Invoice, InvoiceLine, InvoiceLineDetails } from "../../../redux/types";
import {
  patchInvoiceManualItemAPI,
  updateInvoiceItemOrderAPI
} from "../../../services/api-declaration";
import { useTranslate } from "../../../services/appLanguageService";
import { WithLoading } from "../../EditableCommiter";
import { niceAmount } from "../../FormatHelpers";
import { sanitizePrice } from "../../../helpers/globalHelper";

const useStyles = makeStyles((theme) =>
  createStyles({
    rows: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
      "& > *:first-child": {
        display: "flex",
        justifyContent: "space-between",
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(3)
      },
      "& > *:first-child > div": {
        display: "flex"
      },
      "& > *:first-child > div > button:not(:last-child)": {
        marginRight: theme.spacing(1)
      },
      "& > .rows--buttons": {
        display: "flex",
        justifyContent: "end",
        marginTop: theme.spacing(2)
      },
      "& > .rows--buttons > * > button:not(:last-child)": {
        marginRight: theme.spacing(1)
      },
      "& .rows--item--head": {},
      "& .rows--item--head > th:first-child": {
        borderRadius: "10px 0 0 0"
      },
      "& .rows--item--head > th:last-child": {
        borderRadius: "0 10px 0 0"
      },
      "& .rows--item--head > th": {
        backgroundColor: theme.palette.divider,
        fontWeight: "bold"
      },
      "& .rows--item > td": {
        height: "63px"
      },
      "& .rows--item > td:first-child": {
        width: "52px"
      },
      "& .rows--item > td:last-child": {
        width: "92px"
      },
      "& .rows--item--subtitle": {
        color: theme.palette.grey[600],
        fontWeight: "lighter"
      },
      "& .rows--item--subtitle .rows--item--warning": {
        color: theme.palette.error.main
      },
      "& .rows--subitem--head > td:first-child": {
        borderRadius: "10px 0 0 0"
      },
      "& .rows--subitem--head > td:last-child": {
        borderRadius: "0 10px 0 0"
      },
      "& .rows--subitem--head > td": {
        backgroundColor: theme.palette.divider,
        borderTop: `10px solid ${theme.palette.background.paper}`,
        fontWeight: "bold"
      },
      "& .rows--subitem > td": {
        height: "63px"
      },
      "& .rows--subitem > td:last-child": {
        width: "92px"
      },
      "& .rows--subitem--end > td": {
        borderRadius: "0 0 10px 10px",
        backgroundColor: theme.palette.divider,
        borderBottom: `thick solid ${theme.palette.background.paper}`
      },
      "& .disabled": {
        backgroundColor: theme.palette.grey[100]
      }
    },
    selectFortnox: {
      width: "130px",
      marginRight: theme.spacing(2),
      textAlign: "left",
      "& .display": {
        margin: theme.spacing(1)
      }
    }
  })
);

const HoverIconButton: React.FC<{
  icon: React.ReactNode;
  hoverIcon: React.ReactNode;
  swap?: boolean;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ icon, hoverIcon, swap, disabled, onClick }) => {
  const classes = makeStyles(() =>
    createStyles({
      root: {
        "& .hover-icon__shower": {
          display: "flex"
        },
        "&:hover .hover-icon__shower": {
          display: "none"
        },
        "& .hover-icon__hover": {
          display: "none"
        },
        "&:hover .hover-icon__hover": {
          display: "flex"
        }
      }
    })
  )();

  return (
    <IconButton className={classes.root} disabled={disabled} onClick={onClick}>
      <div className="hover-icon__shower">{!swap ? icon : hoverIcon}</div>
      <div className="hover-icon__hover">{!swap ? hoverIcon : icon}</div>
    </IconButton>
  );
};

const ReorderInvoiceRow: React.FC<{
  row: InvoiceLine;
  item: InvoiceLineDetails;
  onUp: () => void;
  onDown: () => void;
}> = ({ row, item, onUp, onDown }) => {
  const is_comment = item.quantity === 0;
  const is_manual = item.item_type === "MANUAL";

  return (
    <TableRow className="rows--item">
      <TableCell></TableCell>
      {is_manual ? (
        <>
          <TableCell>{item.date}</TableCell>
          <TableCell>{item.title}</TableCell>
          <TableCell align="right">
            {!is_comment ? item.quantity : ""}
          </TableCell>
          <TableCell align="right">
            {!is_comment ? niceAmount(item.unit_price) : ""}
          </TableCell>
          <TableCell align="right">
            {!is_comment ? niceAmount(item.total) : ""}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell>{row.date}</TableCell>
          <TableCell>{row.title}</TableCell>
          <TableCell align="right">{row.amount}</TableCell>
          <TableCell align="right">{niceAmount(row.unit_price)}</TableCell>
          <TableCell align="right">{niceAmount(row.total)}</TableCell>
        </>
      )}
      <TableCell align="right">
        <IconButton size="small" onClick={onUp}>
          <ExpandLess />
        </IconButton>
        <IconButton size="small" onClick={onDown}>
          <ExpandMore />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const ManualInvoiceRow: React.FC<{
  item: InvoiceLineDetails;
  fortnoxLookup: Map<string, string>;
  onChange?: (
    payload: Parameters<typeof patchInvoiceManualItemAPI>[2]["item_data"]
  ) => Promise<void>;
  onDelete?: () => Promise<void>;
  onLoadFromContract?: () => Promise<{
    title: string;
    fortnox_type: string;
    unit_price: string;
  } | undefined>;
}> = ({ item, fortnoxLookup, onChange, onDelete, onLoadFromContract }) => {
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [state, setState] = useState<{
    title: string;
    date: string;
    fortnox_type: string;
    quantity: string;
    unit_price: string;
  }>();
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslate("InvoiceView");

  const editing = state !== undefined;
  const is_comment = item.quantity === 0;

  const vat_percentage =
    item.total > 0 ? Math.round(100 * (item.vat / item.total)) : 25;

  const schema = Yup.object().shape({
    title: Yup.string().max(255, t("fieldLengthExceeded")),
    date: Yup.string()
      .required(t("fieldRequired"))
      .matches(/^\d{4}-\d{2}-\d{2}$/, t("fieldDateFormat")),
    fortnox_type: !is_comment
      ? Yup.string().oneOf(Array.from(fortnoxLookup.keys()), t("fieldRequired"))
      : Yup.string(),
    quantity: !is_comment
      ? Yup.number()
          .typeError(t("fieldDigitOnly"))
          .required(t("fieldRequired"))
          .positive(t("fieldPositive"))
      : Yup.number(),
    unit_price: !is_comment
      ? Yup.number().typeError(t("fieldDigitOnly")).required(t("fieldRequired"))
      : Yup.number()
  });

  const validateFields = async (
    patch: Partial<NonNullable<typeof state>> | undefined,
    ...fields: (keyof NonNullable<typeof state>)[]
  ): Promise<boolean> => {
    let next = { ...errors };
    const patched = patch ? { ...state, ...patch } : state;
    await Promise.all(
      (fields.length ? fields : Object.keys(state ?? {})).map((field) =>
        schema
          .validateAt(field, patched)
          .then(() => field in next && delete next[field])
          .catch(
            (err: ValidationError) => (next[field] = err.errors.join(", "))
          )
      )
    ).then(() => setErrors(next));
    return Object.keys(next).length > 0;
  };

  const beforeunloadMessage = t("unsavedChangesMessage");
  useEffect(() => {
    if (editing) {
      const beforeunload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = beforeunloadMessage;
      };
      window.addEventListener("beforeunload", beforeunload);

      const unblock = history.block(() => {
        if (!window.confirm(beforeunloadMessage)) {
          return false;
        }
      });

      return () => {
        unblock();
        window.removeEventListener("beforeunload", beforeunload);
      };
    }
  }, [beforeunloadMessage, editing, history]);

  const handleLoadFromContract = async () => {
    if (onLoadFromContract) {
      const item = await onLoadFromContract()
      if (item) {
        setState(s => ({
          date: "",
          quantity: "",
          ...s,
          ...item,
        }))
      }
    }
  };

  const handleEdit = () => {
    setState({
      title: item.title,
      date: item.date ?? "",
      fortnox_type:
        item.fortnox_data_index > 0 ? item.fortnox_data_index.toString() : "",
      quantity: item.quantity?.toString() ?? "",
      unit_price: item.unit_price?.toString() ?? ""
    });
    setErrors({});
  };

  const handleDelete = () => {
    if (!!onDelete) {
      setDisabled(true);
      void onDelete().then(() => setDisabled(false));
    }
  };

  const handleDone = async () => {
    if (!!onChange && !disabled && state) {
      setDisabled(true);
      const hasErrors = await validateFields(undefined);
      if (!hasErrors) {
        const patch = {
          title: state.title,
          line_break: !state.title.trim().length,
          date: state.date,
          quantity: !is_comment ? parseFloat(state.quantity) || 1 : 0,
          unit_price: parseFloat(state.unit_price),
          fortnox_data_index: !is_comment ? parseInt(state.fortnox_type) : 0
        };
        await onChange(patch);
        setState(undefined);
      }
      setDisabled(false);
    }
  };

  const handleClear = () => {
    setState(undefined);
  };

  const handleChange: <T extends keyof NonNullable<typeof state>>(
    key: T,
    value: NonNullable<typeof state>[T]
  ) => typeof state = (key, value) => {
    if (state) {
      const next = { ...state, [key]: value };
      setState(next);
      return next;
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement | HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      void handleDone();
    }
  };

  return editing ? (
    <TableRow className="rows--item">
      <TableCell></TableCell>
      <TableCell>
        <KeyboardDatePicker
          format="yyyy-MM-dd"
          value={state.date}
          onChange={(_, value) => {
            value && handleChange("date", value);
          }}
          onBlur={() => validateFields(undefined, "date")}
          onKeyDown={handleKeyDown}
          error={!!errors["date"]}
          helperText={errors["date"]}
        />
      </TableCell>
      <TableCell style={{ display: "flex", alignItems: "center" }}>
        <TextField
          fullWidth
          value={state.title}
          onChange={(e) => handleChange("title", e.target.value)}
          onBlur={() => validateFields(undefined, "title")}
          onKeyDown={handleKeyDown}
          error={!!errors["title"]}
          helperText={errors["title"]}
        />
        <IconButton onClick={handleLoadFromContract}>
          <AddCircle />
        </IconButton>
      </TableCell>
      {!is_comment ? (
        <>
          <TableCell align="right">
            <Select
              value={state.fortnox_type}
              renderValue={(v: any) => (
                <span className="display">
                  {v ? fortnoxLookup.get(v) : t("typeLabel")}
                </span>
              )}
              displayEmpty
              onChange={(e) => {
                const next = handleChange(
                  "fortnox_type",
                  e.target.value as string
                );
                validateFields(next, "fortnox_type");
              }}
              error={!!errors["fortnox_type"]}
              className={classes.selectFortnox}
            >
              {Array.from(fortnoxLookup.entries()).map(([id, name]) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              value={state.quantity}
              onChange={(e) => handleChange("quantity", e.target.value)}
              onBlur={() => validateFields(undefined, "quantity")}
              onKeyDown={handleKeyDown}
              error={!!errors["quantity"]}
              helperText={errors["quantity"]}
              style={{ width: "100px" }}
            />
          </TableCell>
          <TableCell align="right">
            <TextField
              value={state.unit_price}
              onChange={(e) =>
                handleChange("unit_price", sanitizePrice(e.target.value))
              }
              onBlur={() => validateFields(undefined, "unit_price")}
              onKeyDown={handleKeyDown}
              error={!!errors["unit_price"]}
              helperText={errors["unit_price"]}
              InputProps={{
                endAdornment: <InputAdornment position="end">kr</InputAdornment>
              }}
              style={{ width: "150px" }}
            />
          </TableCell>
          <TableCell align="right">
            {niceAmount(
              parseFloat(state.unit_price) * parseFloat(state.quantity)
            )}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </>
      )}
      <TableCell align="right">
        <IconButton
          size="small"
          disabled={disabled}
          onClick={() => void handleDone()}
        >
          <Done />
        </IconButton>
        <IconButton size="small" disabled={disabled} onClick={handleClear}>
          <Clear />
        </IconButton>
      </TableCell>
    </TableRow>
  ) : (
    <TableRow className="rows--item">
      <TableCell></TableCell>
      <TableCell>{item.date}</TableCell>
      <TableCell>
        {item.title}{" "}
        {!is_comment && (
          <span className="rows--item--subtitle">
            (
            {fortnoxLookup.get(item.fortnox_data_index.toString()) ??
              t("fieldTypeNotSet")}
            {vat_percentage !== 25 ? (
              <>
                {", "}
                <span className="rows--item--warning">
                  VAT {vat_percentage}%
                </span>
              </>
            ) : null}
            )
          </span>
        )}
      </TableCell>
      <TableCell align="right">{!is_comment ? item.quantity : ""}</TableCell>
      <TableCell align="right">
        {!is_comment ? niceAmount(item.unit_price) : ""}
      </TableCell>
      <TableCell align="right">
        {!is_comment ? niceAmount(item.total) : ""}
      </TableCell>
      <TableCell align="right">
        {!!onChange && (
          <IconButton size="small" disabled={disabled} onClick={handleEdit}>
            <Edit />
          </IconButton>
        )}
        {!!onDelete && (
          <IconButton size="small" disabled={disabled} onClick={handleDelete}>
            <Delete />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

const InvoiceRow: React.FC<{
  row: InvoiceLine;
  fortnoxLookup: Map<string, string>;
  onItemChecked?: (item: {
    invoice_item_id: number;
    invoice_item_object_type: string;
    disabled: boolean;
  }) => Promise<void>;
}> = ({ row, fortnoxLookup, onItemChecked }) => {
  const [expanded, setExpanded] = useState(false);
  const t = useTranslate("InvoiceView");

  const isServicePackage =
    row.items?.some((item) => item.item_type === "SERVICEPACKAGE") ?? false;
  const items = row.items?.map((item) => [item, ...item.subitems]).flat() ?? [];

  return (
    <>
      <TableRow
        className={`rows--item ${row.items?.[0].disabled ? "disabled" : ""}`}
        onClick={() => items.length > 0 && setExpanded((e) => !e)}
      >
        <TableCell align="center">
          {items.length > 0 && (
            <IconButton>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{row.date}</TableCell>
        <TableCell>
          {row.title}{" "}
          <span className="rows--item--subtitle">
            (
            {fortnoxLookup.get(row.fortnox_data_index.toString()) ??
              t("fieldTypeNotSet")}
            )
          </span>
        </TableCell>
        <TableCell align="right">{row.amount}</TableCell>
        <TableCell align="right">{niceAmount(row.unit_price)}</TableCell>
        <TableCell align="right">{niceAmount(row.total)}</TableCell>
        <TableCell align="right">
          <WithLoading
            onChange={async () => {
              await Promise.all(
                row.items?.map((item) => onItemChecked?.(item)) ?? []
              );
            }}
          >
            {(onChange) => (
              <HoverIconButton
                icon={<Done />}
                hoverIcon={<Cancel />}
                swap={row.items?.[0].disabled}
                disabled={!onItemChecked}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange();
                }}
              />
            )}
          </WithLoading>
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow className="rows--subitem--head">
          <TableCell size="small"></TableCell>
          <TableCell size="small">{t("timeLabel")}</TableCell>
          <TableCell size="small">{t("descriptionLabel")}</TableCell>
          <TableCell size="small" align="right">
            {t("amountLabel")}
          </TableCell>
          <TableCell size="small" align="right">
            {t("unitPriceLabel")}
          </TableCell>
          <TableCell size="small" align="right">
            {t("totalLabel")}
          </TableCell>
          <TableCell size="small"></TableCell>
        </TableRow>
      )}
      {expanded &&
        items.map((subitem, index) => {
          return (
            <TableRow
              key={index}
              className={`rows--subitem ${subitem.disabled ? "disabled" : ""}`}
            >
              <TableCell></TableCell>
              <TableCell>
                {(subitem.in_time || subitem.out_time) && (
                  <>
                    {subitem.in_time?.slice(11)} - {subitem.out_time?.slice(11)}
                  </>
                )}
              </TableCell>
              <TableCell>
                {subitem.item_type} - {subitem.title}
              </TableCell>
              <TableCell align="right">{subitem.quantity}</TableCell>
              <TableCell align="right">
                {niceAmount(subitem.unit_price)}
                {subitem.unit ? "/" : ""}
                {subitem.unit}
              </TableCell>
              <TableCell align="right">{niceAmount(subitem.total)}</TableCell>
              <TableCell align="right">
                <WithLoading onChange={async () => onItemChecked?.(subitem)}>
                  {(onChange) =>
                    !isServicePackage ? (
                      <HoverIconButton
                        icon={<Done />}
                        hoverIcon={<Cancel />}
                        swap={subitem.disabled}
                        disabled={!onItemChecked}
                        onClick={(e) => {
                          e.stopPropagation();
                          onChange();
                        }}
                      />
                    ) : (
                      <IconButton disabled>
                        <Cancel />
                      </IconButton>
                    )
                  }
                </WithLoading>
              </TableCell>
            </TableRow>
          );
        })}
      {expanded && (
        <TableRow className="rows--subitem--end">
          <TableCell colSpan={7}></TableCell>
        </TableRow>
      )}
    </>
  );
};

type InvoiceRowsProps = {
  rows: Invoice["summary"]["lines"];
  fortnoxLookup: Map<string, string>;
  onItemChecked?: (item: {
    invoice_item_id: number;
    invoice_item_object_type: string;
    disabled: boolean;
  }) => Promise<void>;
  onAdd?: (type: "comment" | "row") => Promise<void>;
  onChange?: (
    item_id: number,
    item_data: Parameters<typeof patchInvoiceManualItemAPI>[2]["item_data"]
  ) => Promise<void>;
  onDelete?: (item_id: number) => Promise<void>;
  onReorder?: (
    payload: Parameters<typeof updateInvoiceItemOrderAPI>[2]
  ) => Promise<void>;
  onLoadFromContract?: () => Promise<{
    title: string;
    fortnox_type: string;
    unit_price: string;
  } | undefined>;
};

const InvoiceRows: React.FC<InvoiceRowsProps> = ({
  rows,
  fortnoxLookup,
  onItemChecked,
  onAdd,
  onChange,
  onDelete,
  onReorder,
  onLoadFromContract,
}) => {
  const [editOrder, setEditOrder] = useState(false);
  const [sortOrder, setSortOrder] = useState<number[]>([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const t = useTranslate("InvoiceView");

  const beforeunloadMessage = t("unsavedChangesMessage");
  useEffect(() => {
    if (editOrder) {
      const beforeunload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = beforeunloadMessage;
      };
      window.addEventListener("beforeunload", beforeunload);

      const unblock = history.block(() => {
        if (!window.confirm(beforeunloadMessage)) {
          return false;
        }
      });

      return () => {
        unblock();
        window.removeEventListener("beforeunload", beforeunload);
      };
    }
  }, [beforeunloadMessage, editOrder, history]);

  const moveUp = (sortOrderIndex: number) => {
    if (sortOrderIndex > 0 && sortOrderIndex < rows.length) {
      const clone = [...sortOrder];
      const other = clone[sortOrderIndex - 1];
      clone[sortOrderIndex - 1] = clone[sortOrderIndex];
      clone[sortOrderIndex] = other;
      setSortOrder(clone);
    }
  };
  const moveDown = (sortOrderIndex: number) => {
    if (sortOrderIndex >= 0 && sortOrderIndex < rows.length - 1) {
      const clone = [...sortOrder];
      const other = clone[sortOrderIndex + 1];
      clone[sortOrderIndex + 1] = clone[sortOrderIndex];
      clone[sortOrderIndex] = other;
      setSortOrder(clone);
    }
  };

  const startChangeOrder = () => {
    setSortOrder(rows.map((_, index) => index));
    setEditOrder(true);
  };

  const handleSave = () => {
    setLoadingSave(true);
    const payload = sortOrder.flatMap((index, sort_order) => {
      const row = rows[index];
      return row && row.sort_order !== sort_order
        ? row.items!.map(({ invoice_item_id, invoice_item_object_type }) => ({
            invoice_item_id,
            invoice_item_object_type,
            sort_order
          }))
        : [];
    });
    void onReorder?.(payload).then(() => {
      setLoadingSave(false);
      setEditOrder(false);
    });
  };

  const handleClear = () => {
    setEditOrder(false);
  };

  return (
    <Paper className={classes.rows}>
      <div>
        <Typography variant="h4">{t("rowsHeader")}</Typography>
        {!editOrder ? (
          <Button
            color="primary"
            variant="contained"
            startIcon={<Loop />}
            disabled={!onReorder}
            onClick={startChangeOrder}
          >
            {t("changeOrderButton")}
          </Button>
        ) : (
          <div>
            <Button
              color="primary"
              variant="contained"
              disabled={loadingSave}
              startIcon={
                loadingSave ? <CircularProgress size={20} /> : <Done />
              }
              onClick={handleSave}
            >
              {t("saveButton")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Clear />}
              onClick={handleClear}
            >
              {t("clearButton")}
            </Button>
          </div>
        )}
      </div>
      <Table size="small">
        <TableHead>
          <TableRow className="rows--item--head">
            <TableCell></TableCell>
            <TableCell width="180px">{t("dateLabel")}</TableCell>
            <TableCell width="35%">{t("descriptionLabel")}</TableCell>
            <TableCell align="right">{t("amountLabel")}</TableCell>
            <TableCell width="180px" align="right">
              {t("unitPriceLabel")}
            </TableCell>
            <TableCell width="180px" align="right">
              {t("totalLabel")}
            </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {editOrder &&
            sortOrder?.map((index, sortOrder) => {
              const row = rows[index]!;
              const item = row.items?.[0];
              return (
                item && (
                  <ReorderInvoiceRow
                    key={item.invoice_item_id!}
                    row={row}
                    item={item}
                    onUp={() => moveUp(sortOrder)}
                    onDown={() => moveDown(sortOrder)}
                  />
                )
              );
            })}
          {!editOrder &&
            rows.map((row) =>
              row.items?.[0].item_type === "MANUAL" ? (
                row.items?.map((item) => (
                  <ManualInvoiceRow
                    key={item.invoice_item_id}
                    item={item}
                    fortnoxLookup={fortnoxLookup}
                    onChange={
                      onChange
                        ? (payload) => onChange(item.invoice_item_id, payload)
                        : undefined
                    }
                    onDelete={
                      onDelete
                        ? () => onDelete(item.invoice_item_id)
                        : undefined
                    }
                    onLoadFromContract={onLoadFromContract}
                  />
                ))
              ) : (
                <InvoiceRow
                  key={row.items?.[0].invoice_item_id}
                  row={row}
                  fortnoxLookup={fortnoxLookup}
                  onItemChecked={onItemChecked}
                />
              )
            )}
        </TableBody>
      </Table>
      <div className={"rows--buttons"}>
        <div>
          <Button
            color="primary"
            variant="contained"
            disabled={editOrder || !onAdd}
            onClick={() => onAdd?.("row")}
            startIcon={<Add />}
          >
            {t("addRowButton")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={editOrder || !onAdd}
            onClick={() => onAdd?.("comment")}
            startIcon={<Add />}
          >
            {t("addCommentButton")}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default InvoiceRows;
