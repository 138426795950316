import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  WithStyles
} from "@material-ui/core";
import { useTranslate } from "../../../services/appLanguageService";
import MapView from "../../contracts/tasks/Map";
import { FieldArrayRenderProps } from "formik";
import {
  Customerobject,
  FeatureCollection,
  RouteSegment
} from "../../../redux/types";
import { useEffect, useState } from "react";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { getCustomerobjectByTaskAPI } from "../../../services/api-declaration";

const styles = (theme: any) => createStyles({});

interface RouteSegmentMapDialogProps extends WithStyles<typeof styles> {
  handleClose: () => void;
  mapDialogData: {
    row: RouteSegment | null;
    geo_polygons: FeatureCollection | null;
    open: boolean;
    arrHelpers: FieldArrayRenderProps | null;
    index: number | null;
    editable: boolean;
  };
}

const RouteSegmentMapDialog: React.FC<RouteSegmentMapDialogProps> = (props) => {
  const { handleClose, mapDialogData } = props;

  const t = useTranslate("RouteSegmentMapDialog");

  const [saveEvent, setSaveEvent] = useState(false);
  const [customerobject, setCustomerobject] = useState<Customerobject | null>(
    null
  );

  useEffect(() => {
    if (!mapDialogData.open) {
      setSaveEvent(false);
    }
  }, [mapDialogData.open]);

  useEffect(() => {
    (async () => {
      if (mapDialogData.row && mapDialogData.row.task !== -1) {
        const response = await getCustomerobjectByTaskAPI(
          mapDialogData.row.task
        );
        if (response.results.length > 0) {
          setCustomerobject(response.results[0]);
        }
      }
    })();
  }, [mapDialogData.row?.task, mapDialogData.row]);

  return (
    <Dialog
      open={mapDialogData.open}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">{t("mapDialogTitle")}</DialogTitle>
      <DialogContent>
        {mapDialogData.row && mapDialogData.arrHelpers && (
          <MapView
            geo_polygons={
              mapDialogData.row.geo_polygons ?? mapDialogData.geo_polygons
            }
            editable={mapDialogData.editable}
            mapDialogData={mapDialogData}
            saveEvent={saveEvent}
            showSaveButton={false}
            defaultLatitude={
              customerobject ? customerobject.location?.latitude : undefined
            }
            defaultLongitude={
              customerobject ? customerobject.location?.longitude : undefined
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSaveEvent(true);
            handleClose();
          }}
          color="primary"
        >
          <CheckCircleOutlineOutlinedIcon />
          {t("saveButtonLabel")}
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          <CancelOutlinedIcon />
          {t("cancelButtonLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(RouteSegmentMapDialog);
