import React, { useCallback, useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  WithStyles,
  Paper,
  Typography,
  Button,
  InputLabel
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import {
  FormikSubmitDispatchProps,
  TemplateImageForm,
  TemplateImage,
  ImageRow,
  TabComponentProps
} from "../../redux/types";
import { Link, LinkProps } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useTranslate } from "../../services/appLanguageService";
import { getImageByURL } from "../../services/imageHelperService";
import ImageUploadComponent from "../imageUploadComponent/ImageUploadComponent";
import store from "../../redux/store";
import { setTitle } from "../../redux/reducers/tabRouter/tabTitle/actions";

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    invoice_reference: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .nullable()
      .notRequired(),
    notes: Yup.string().nullable().notRequired()
  });

const styles = (theme: any) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    selectLabel: {
      marginTop: theme.spacing(3)
    },
    select: {
      marginTop: theme.spacing(1)
    },
    imageLabel: {
      marginTop: theme.spacing(3)
    },
    errMessage: {
      color: "#f44336",
      fontSize: "0.75rem",
      marginTop: theme.spacing(0.5)
    }
  });

interface NewtemplateImageProps
  extends FormikSubmitDispatchProps<TemplateImageForm>,
    WithStyles<typeof styles>,
    TabComponentProps {
  templateImage?: TemplateImage;
}

const NewEdittemplateImage: React.FC<NewtemplateImageProps> = (props) => {
  const { classes, templateImage, tabId } = props;
  const t = useTranslate("TemplateImagePage");
  const t3 = useTranslate("ValidationErrorMessages");
  const [images, setImages] = useState<ImageRow[]>([]);
  let initialValues: TemplateImage | TemplateImageForm;
  if (templateImage && (templateImage as TemplateImage).id) {
    initialValues = {
      ...templateImage
    };
  } else {
    initialValues = {
      id: -1,
      name: "",
      image: null,
      notes: ""
    };
  }

  const handleFormSubmit = (
    values: TemplateImageForm,
    actions: FormikHelpers<TemplateImageForm>
  ) => {
    let checkedValues: any;
    const imageStr = images.length ? images[0].imageStr : null;

    if (!imageStr || (imageStr && imageStr.includes("base64"))) {
      checkedValues = { ...values, image: imageStr };
    } else {
      const { image, ...other } = values;
      checkedValues = other;
    }
    props.handleSubmit(checkedValues, actions);
  };

  const getImage = async () => {
    if (templateImage && templateImage.image) {
      const imageStr = await getImageByURL(templateImage.image);
      setImages([
        {
          imageStr
        }
      ]);
    }
  };
  const getImageCb = useCallback(getImage, [templateImage]);
  useEffect(() => {
    getImageCb();
  }, [templateImage, getImageCb]);
  useEffect(() => {
    if (templateImage) {
      store.dispatch(setTitle(templateImage.name, tabId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateImage]);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
          (props, ref) => (
            <Link
              to={`/services/templateimages${
                templateImage ? `/${templateImage.id}` : ""
              }?same_tab=true`}
              {...props}
              ref={ref as any}
            />
          )
        )}
      >
        <NavigateBeforeIcon /> {t("navigateBeforeIconLabel")}
      </Button>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h6">
          {templateImage
            ? t("editTemplateImageHeading")
            : t("newTemplateImageHeading")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={ValidationSchema(t3)}
          onSubmit={handleFormSubmit}
        >
          {(props) => (
            <form
              className={classes.form}
              onSubmit={props.handleSubmit}
              autoComplete="off"
            >
              <Field
                id="templateImage-form-name"
                type="text"
                name="name"
                label={t("nameLabel")}
                placeholder={t("nameLabel")}
                component={TextField}
                margin="normal"
                fullWidth
              />

              <InputLabel
                className={
                  props.errors.image && props.touched.image ? "Mui-error" : ""
                }
              >
                {t("imageLabel")}
              </InputLabel>
              <ImageUploadComponent
                images={images}
                onImageUpdate={setImages}
                singleImageMode
              />
              {props.errors.image && (
                <div className={classes.errMessage}>{t3("requiredError")}</div>
              )}

              <Field
                id="new-templateImage-form-notes"
                type="text"
                name="notes"
                label={t("notesLabel")}
                placeholder={t("notesLabel")}
                component={TextField}
                multiline={true}
                margin="normal"
                rows="4"
                fullWidth
              />

              <Button
                id="new-templateImage-form-submit"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {templateImage ? t("saveButtonLabel") : t("createButtonLabel")}
              </Button>
            </form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(NewEdittemplateImage);
