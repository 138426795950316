import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Paper,
  createStyles,
  makeStyles,
  MenuItem,
  Typography,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import {
  ChevronLeft,
  FileCopy as FileCopyIcon,
  PictureAsPdf,
  Send,
  LocalOffer
} from "@material-ui/icons";
import { saveAs } from "file-saver";
import { Link as RouterLink, useHistory } from "react-router-dom";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import {
  EmailStatus,
  Invoice,
  InvoiceStatus,
  TabComponentProps
} from "../../../redux/types";
import {
  getContractorInvoiceAPI,
  getContractorInvoicePdfAPI,
  getCustomerInvoiceAPI,
  getCustomerInvoicePdfAPI,
  updateContractorInvoiceAPI,
  updateCustomerInvoiceAPI,
  updateCustomerInvoiceItemStatusAPI,
  updateContractorInvoiceItemStatusAPI,
  createInvoiceManualItemAPI,
  deleteInvoiceManualItemAPI,
  patchInvoiceManualItemAPI,
  updateInvoiceItemOrderAPI,
  sendContractorInvoiceEmailAPI,
  sendCustomerInvoiceEmailAPI,
  getInvoiceFortnoxTypesAPI,
  createManualInvoiceAPI,
  getContractManualItemsAPI
} from "../../../services/api-declaration";
import { useTranslate } from "../../../services/appLanguageService";
import { InvoicesSubIndexesEnum } from "../../../strings/TabRouterStrings";
import ConfirmationDialog from "../../confirmationDialog/ConfirmationDialog";
import InvoiceRows from "./InvoiceRows";
import InvoiceHeader from "./InvoiceHeader";
import InvoicePreview from "./InvoicePreview";
import { pick } from "lodash";
import { WithLoading } from "../../EditableCommiter";
import {
  getInvoiceEmailStatusIconWithColor,
  getInvoiceStatusIconWithColor
} from "../../../helpers/invoiceHelper";
import { showGlobalSnackbar, slugify } from "../../../helpers/globalHelper";
import { niceAmount } from "../../FormatHelpers";

const useStyles = makeStyles((theme) =>
  createStyles({
    actions: {
      display: "flex",
      marginBottom: theme.spacing(2),
      "& > div": {
        display: "flex"
      },
      "& > div:first-child": {
        marginRight: theme.spacing(2)
      },
      "& > div:last-child": {
        marginLeft: "auto"
      },
      "& > button, & > div > *": {
        marginRight: theme.spacing(1)
      }
    },
    statusEditor: {
      display: "flex",
      "& > div:not(:last-child)": {
        display: "flex",
        alignItems: "end",
        paddingBottom: theme.spacing(1),
        marginRight: theme.spacing(3)
      },
      "& > div:last-child": {
        display: "flex",
        alignItems: "end",
        marginLeft: theme.spacing(1),
        width: "200px"
      },
      "& .status-editor--select": {
        height: "44px"
      }
    },
    iconLabel: {
      marginLeft: theme.spacing(1)
    },
    loadingContainer: {
      display: "flex",
      height: "calc(100vh - 200px)",
      "& > *": {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(7)
      }
    }
  })
);

type InvoiceStatusActions = {
  [status in InvoiceStatus]?: () => Promise<void>;
};

type InvoiceStatusEditorProps = {
  status?: InvoiceStatus;
  emailStatus?: EmailStatus;
  statusActions: InvoiceStatusActions;
  onCancel: () => void;
};

const InvoiceStatusEditor: React.FC<InvoiceStatusEditorProps> = ({
  status,
  emailStatus,
  statusActions,
  onCancel
}) => {
  const classes = useStyles();
  const t = useTranslate("InvoiceView");

  const statusKeys = Object.keys(statusActions) as InvoiceStatus[];

  const handleStatus = async (status: InvoiceStatus) => {
    if (status === "CANCELLED") {
      onCancel();
    } else {
      await statusActions[status]?.();
    }
  };

  return (
    <div className={classes.statusEditor}>
      {status !== undefined && (
        <div>
          {getInvoiceStatusIconWithColor(status)}
          <div className={classes.iconLabel}>
            {t(`${status.toLowerCase()}StatusLabel`)}
          </div>
        </div>
      )}
      {emailStatus !== undefined && (
        <div>
          {getInvoiceEmailStatusIconWithColor(emailStatus)}
          <div className={classes.iconLabel}>
            {t(`${emailStatus.toLowerCase()}EmailStatusLabel`)}
          </div>
        </div>
      )}

      <WithLoading onChange={handleStatus}>
        {(onChange) => (
          <Select
            variant="outlined"
            onChange={(e) => onChange(e.target.value as InvoiceStatus)}
            renderValue={() => (
              <Typography className={classes.iconLabel}>
                {t("changeStatusLabel")}
              </Typography>
            )}
            className="status-editor--select"
            displayEmpty
            fullWidth
          >
            {statusKeys.length === 0 && (
              <MenuItem value="">{t("terminalStatusLabel")}</MenuItem>
            )}
            {statusKeys.map(
              (s) =>
                statusActions[s] && (
                  <MenuItem value={s}>
                    {getInvoiceStatusIconWithColor(s)}
                    <div className={classes.iconLabel}>
                      {t(`${s.toLowerCase()}StatusLabel`)}
                    </div>
                  </MenuItem>
                )
            )}
          </Select>
        )}
      </WithLoading>
    </div>
  );
};

type PatchInvoiceF = (
  patch: Partial<Exclude<Invoice, "id" | "oca">>
) => Promise<void>;

type InvoiceActionsProps = {
  type: "contractorinvoices" | "customerinvoices";
  status?: InvoiceStatus;
  emailStatus?: EmailStatus;
  onDownload: () => void;
  onSend?: () => void;
  onCopy: () => void;
  onCancel: () => void;
  statusActions: InvoiceStatusActions;
};
const InvoiceActions: React.FC<InvoiceActionsProps> = ({
  type,
  status,
  emailStatus,
  onDownload,
  onSend,
  onCopy,
  onCancel,
  statusActions
}) => {
  const classes = useStyles();
  const t = useTranslate("InvoiceView");

  return (
    <div className={classes.actions}>
      <div>
        <Button
          data-cy="overview-btn"
          color="primary"
          variant="contained"
          startIcon={<ChevronLeft />}
          component={RouterLink}
          to={`/invoices/${type}`}
        >
          {t("backButtonLabel")}
        </Button>
        <Button
          data-cy="download-btn"
          color="primary"
          variant="contained"
          startIcon={<PictureAsPdf />}
          onClick={() => onDownload()}
        >
          {t("downloadButtonLabel")}
        </Button>
        <Button
          data-cy="send-btn"
          color="primary"
          variant="contained"
          startIcon={<Send />}
          disabled={!onSend}
          onClick={() => onSend?.()}
        >
          {t("sendButtonLabel")}
        </Button>
        <Button
          data-cy="copy-btn"
          color="primary"
          variant="contained"
          startIcon={<FileCopyIcon />}
          onClick={() => onCopy()}
        >
          {t("copyButtonLabel")}
        </Button>
      </div>

      <InvoiceStatusEditor
        status={status}
        emailStatus={emailStatus}
        statusActions={statusActions}
        onCancel={onCancel}
      />
    </div>
  );
};

const useStatusActions: (
  allowed_transitions: Invoice["allowed_transitions"],
  patchInvoice: PatchInvoiceF
) => InvoiceStatusActions = (allowed_transitions, patchInvoice) =>
  Object.fromEntries(
    allowed_transitions.map((status) => [
      status,
      () => patchInvoice({ status })
    ])
  );

const InvoiceView: React.FC<TabComponentProps<"invoiceId">> = ({
  tabId,
  routeParams: { invoiceId },
  subTabIndex
}) => {
  const queueRef = useRef<Promise<{ oca: number }>>();
  const pdfRefreshRef = useRef<{
    timeoutRef: number | null;
    running: boolean;
    refresh: boolean;
  }>({ timeoutRef: null, running: false, refresh: false });
  const [invoice, setInvoice] = useState<Invoice>();
  const [fortnoxLookup, setFortnoxLookup] = useState<Map<string, string>>();
  const [loading, setLoading] = useState(true);
  const [pdfBlob, setPdfBlob] = useState<Blob>();
  const [pdfLoading, setPdfLoading] = useState(true);
  const [modal, setModal] = useState<
    | {
        m: "cancelInvoice";
        resolve: () => void;
      }
    | {
        m: "deleteItem";
        item_id: number;
        resolve: () => void;
      }
    | {
        m: "sendInvoice";
        resolve: () => void;
      }
    | {
        m: "copyInvoice";
        resolve: () => void;
      }
    | {
        m: "loadFromContract";
        resolve: (item: { 
          title: string;
          fortnox_type: string;
          unit_price: string;
        } | undefined) => void;
      }
  >();
  const classes = useStyles();
  const t = useTranslate("InvoiceView");
  const history = useHistory();

  const canEdit = invoice?.status === "DRAFT";
  const fileName = `${t("filenamePrefix")}_${invoice?.number ?? "-"}_${slugify(
    invoice?.customerobject_name ?? invoice?.company_name ?? "-"
  )}_${invoice?.invoice_date}.pdf`;

  const invoiceType =
    subTabIndex === InvoicesSubIndexesEnum.CUSTOMER
      ? "customerinvoices"
      : "contractorinvoices";

  useEffect(() => {
    let alive = true;

    const getAPI =
      invoiceType === "contractorinvoices"
        ? getContractorInvoiceAPI
        : getCustomerInvoiceAPI;
    const getPdfAPI =
      invoiceType === "contractorinvoices"
        ? getContractorInvoicePdfAPI
        : getCustomerInvoicePdfAPI;

    setLoading(true);
    setPdfLoading(true);

    void Promise.all([
      getAPI(invoiceId),
      getInvoiceFortnoxTypesAPI(invoiceType)
    ]).then(([invoice, types]) => {
      if (alive) {
        setInvoice(invoice);
        setFortnoxLookup(new Map(types.map((t) => [t.id, t.name])));
        setLoading(false);
        store.dispatch(setTitle(invoice.company_name, tabId));
      }
    });

    void getPdfAPI(invoiceId).then((pdfBlob) => {
      if (alive) {
        setPdfBlob(pdfBlob);
        setPdfLoading(false);
      }
    });

    return () => {
      alive = false;
    };
  }, [invoiceId, invoiceType, tabId]);

  const refreshPdf = () => {
    if (pdfRefreshRef.current.running) {
      pdfRefreshRef.current.refresh = true;
    } else {
      if (!pdfLoading) {
        setPdfLoading(true);
      }
      if (pdfRefreshRef.current.timeoutRef) {
        window.clearTimeout(pdfRefreshRef.current.timeoutRef);
      }
      pdfRefreshRef.current.timeoutRef = window.setTimeout(() => {
        const getPdfAPI =
          invoiceType === "contractorinvoices"
            ? getContractorInvoicePdfAPI
            : getCustomerInvoicePdfAPI;

        const fetch = async (): Promise<void> => {
          const pdfBlob = await getPdfAPI(invoiceId);
          if (!pdfRefreshRef.current.refresh) {
            pdfRefreshRef.current.running = false;
            setPdfBlob(pdfBlob);
            setPdfLoading(false);
          } else {
            pdfRefreshRef.current.refresh = false;
            return await fetch();
          }
        };

        pdfRefreshRef.current.timeoutRef = null;
        pdfRefreshRef.current.running = true;
        void fetch();
      }, 500);
    }
  };

  const patchInvoice: PatchInvoiceF = async (patch): Promise<void> => {
    if (invoice) {
      const updateAPI =
        invoiceType === "contractorinvoices"
          ? updateContractorInvoiceAPI
          : updateCustomerInvoiceAPI;

      const prePatch = pick(invoice, Object.keys(patch));
      setInvoice({ ...invoice, ...patch });

      const promise = Promise.resolve(queueRef.current ?? { oca: invoice.oca })
        .then(async ({ oca }) => {
          const r = await updateAPI(invoiceId, { ...patch, oca });
          setInvoice(r);
          refreshPdf();
          return { oca: r.oca };
        })
        .catch(() => {
          setInvoice((c) => (c !== undefined ? { ...c, ...prePatch } : c));
          return { oca: invoice.oca };
        });

      queueRef.current = promise;
      await promise;
    }
  };

  const openCancelInvoiceModal = (): Promise<void> =>
    new Promise<void>((resolve) => setModal({ m: "cancelInvoice", resolve }));

  const handleCancelInvoice = async () => {
    await statusActions["CANCELLED"]?.();
    setModal(undefined);
  };

  const openSendInvoiceModal = (): Promise<void> =>
    new Promise<void>((resolve) => setModal({ m: "sendInvoice", resolve }));

  const sendInvoiceEmail = async () => {
    if (invoice) {
      const sendEmailApi =
        invoiceType === "contractorinvoices"
          ? sendContractorInvoiceEmailAPI
          : sendCustomerInvoiceEmailAPI;

      const promise = Promise.resolve(queueRef.current ?? { oca: invoice.oca })
        .then(async ({ oca }) => {
          const r = await sendEmailApi(invoiceId, { oca });
          setInvoice((c) =>
            c !== undefined ? { ...c, oca: r.oca, email_status: "DRAFT" } : c
          );
          showGlobalSnackbar(t("sendingSuccessful"), "success");
          return { oca: r.oca };
        })
        .catch(() => {
          showGlobalSnackbar(t("sendingFailed"), "error");
          return { oca: invoice.oca };
        })
        .finally(() => setModal(undefined));

      queueRef.current = promise;
      await promise;
    }
  };

  const openCopyModal = (): Promise<void> =>
    new Promise<void>((resolve) => setModal({ m: "copyInvoice", resolve }));

  const copyInvoice = async (quantityFactor: number) => {
    if (invoice) {
      const promise = Promise.resolve(queueRef.current ?? { oca: invoice.oca })
        .then(async ({ oca }) => {
          const copiedInvoice = await createManualInvoiceAPI(invoiceType, {
            contract: invoice.contract,
            customerobject: invoice.customerobject,
            period_start: invoice.cover_start,
            period_end: invoice.cover_end,
            copy_from_invoice_id: invoice.id,
            copy_quantity_factor: quantityFactor
          });
          history.push(`/invoices/${invoiceType}/${copiedInvoice.id}`);
          return { oca };
        })
        .catch(() => {
          showGlobalSnackbar(t("copyFailed"), "error");
          return { oca: invoice.oca };
        })
        .finally(() => setModal(undefined));

      queueRef.current = promise;
      await promise;
    }
  };

  const checkInvoiceItem = async (item: {
    invoice_item_id: number;
    invoice_item_object_type: string;
    disabled: boolean;
  }): Promise<void> => {
    if (invoice) {
      const updateItemStatusAPI =
        invoiceType === "contractorinvoices"
          ? updateContractorInvoiceItemStatusAPI
          : updateCustomerInvoiceItemStatusAPI;

      const promise = Promise.resolve(queueRef.current ?? { oca: invoice.oca })
        .then(async () => {
          const r = await updateItemStatusAPI(invoiceId, {
            invoice_item_id: item.invoice_item_id,
            invoice_item_object_type: item.invoice_item_object_type,
            disabled: !item.disabled
          });
          setInvoice(r);
          refreshPdf();
          return { oca: r.oca };
        })
        .catch(() => ({ oca: invoice.oca }));

      queueRef.current = promise;
      await promise;
    }
  };

  const addInvoiceRow = async (type: "comment" | "row"): Promise<void> => {
    if (invoice) {
      const promise = Promise.resolve(queueRef.current ?? { oca: invoice.oca })
        .then(async ({ oca }) => {
          const sort_order =
            Math.max(
              ...invoice.summary.lines
                .map((l) => l.items?.map((item) => item.sort_order) ?? [])
                .flat()
            ) + 1;
          const r = await createInvoiceManualItemAPI(invoiceType, invoiceId, {
            oca,
            title: type === "comment" ? "Comment" : "Manual Item",
            date: invoice.cover_start,
            quantity: type === "comment" ? 0 : 1,
            sort_order: Number.isFinite(sort_order) ? sort_order : -1
          });
          setInvoice(r);
          refreshPdf();
          return { oca: r.oca };
        })
        .catch(() => ({ oca: invoice.oca }));

      queueRef.current = promise;
      await promise;
    }
  };

  const openDeleteItemModal = (item_id: number): Promise<void> =>
    new Promise<void>((resolve) =>
      setModal({ m: "deleteItem", item_id, resolve })
    );

  const handleDeleteItem = async (): Promise<void> => {
    if (invoice && modal?.m === "deleteItem") {
      const { item_id, resolve } = modal;

      const promise = Promise.resolve(queueRef.current ?? { oca: invoice.oca })
        .then(async ({ oca }) => {
          const r = await deleteInvoiceManualItemAPI(invoiceType, invoiceId, {
            oca,
            item_id
          });
          setInvoice(r);
          refreshPdf();
          resolve();
          return { oca: r.oca };
        })
        .catch(() => ({ oca: invoice.oca }))
        .finally(() => setModal(undefined));

      queueRef.current = promise;
      await promise;
    }
  };

  const patchInvoiceRow = async (
    item_id: number,
    payload: Parameters<typeof patchInvoiceManualItemAPI>[2]["item_data"]
  ): Promise<void> => {
    if (invoice) {
      const promise = Promise.resolve(queueRef.current ?? { oca: invoice.oca })
        .then(async ({ oca }) => {
          const r = await patchInvoiceManualItemAPI(invoiceType, invoiceId, {
            oca,
            item_id,
            item_data: payload
          });
          setInvoice(r);
          refreshPdf();
          return { oca: r.oca };
        })
        .catch(() => ({ oca: invoice.oca }));

      queueRef.current = promise;
      await promise;
    }
  };

  const reorderRows = async (
    payload: Parameters<typeof updateInvoiceItemOrderAPI>[2]
  ): Promise<void> => {
    if (invoice) {
      const promise = Promise.resolve(queueRef.current ?? { oca: invoice.oca })
        .then(async () => {
          const r = await updateInvoiceItemOrderAPI(
            invoiceType,
            invoiceId,
            payload
          );
          setInvoice(r);
          refreshPdf();
          return { oca: r.oca };
        })
        .catch(() => ({ oca: invoice.oca }));

      queueRef.current = promise;
      await promise;
    }
  };

  const loadFromContract = async () => new Promise<{ title: string, fortnox_type: string, unit_price: string } | undefined>(
    (resolve) => setModal({ m: "loadFromContract", resolve })
  );

  const statusActions = useStatusActions(
    invoice?.allowed_transitions ?? [],
    patchInvoice
  );

  return (
    <>
      <InvoiceActions
        type={invoiceType}
        status={invoice?.status}
        emailStatus={invoice?.email_status ?? undefined}
        onSend={
          invoice?.status && invoice.status !== "DRAFT"
            ? openSendInvoiceModal
            : undefined
        }
        onCopy={openCopyModal}
        onCancel={openCancelInvoiceModal}
        onDownload={() => pdfBlob && saveAs(pdfBlob, fileName)}
        statusActions={statusActions}
      />
      {!loading && invoice && fortnoxLookup ? (
        <>
          <InvoiceHeader
            paymentTerms={invoice.payment_terms}
            invoiceNumber={invoice.number?.toString() ?? "-"}
            invoiceDate={invoice.invoice_date}
            invoiceDueDate={invoice.due_date}
            senderInfo={invoice.details.sender_info}
            recipientInfo={invoice.details.recipient_info}
            summary={invoice.summary}
            reference={invoice.reference}
            patchInvoice={patchInvoice}
            canEdit={canEdit}
          />
          <InvoiceRows
            rows={invoice.summary.lines}
            fortnoxLookup={fortnoxLookup}
            onItemChecked={canEdit ? checkInvoiceItem : undefined}
            onAdd={canEdit ? addInvoiceRow : undefined}
            onChange={canEdit ? patchInvoiceRow : undefined}
            onDelete={canEdit ? openDeleteItemModal : undefined}
            onReorder={canEdit ? reorderRows : undefined}
            onLoadFromContract={canEdit ? loadFromContract : undefined}
          />
          {pdfBlob ? (
            <InvoicePreview blob={pdfBlob} loading={pdfLoading} />
          ) : (
            <Paper className={classes.loadingContainer}>
              <CircularProgress />
            </Paper>
          )}
        </>
      ) : (
        <Paper className={classes.loadingContainer}>
          <CircularProgress />
        </Paper>
      )}
      {modal?.m === "cancelInvoice" && (
        <ConfirmationDialog
          title={t("confirmCancelInvoiceTitle")}
          description={t("confirmCancelInvoiceText")}
          onSuccess={handleCancelInvoice}
          onClose={() => {
            modal?.resolve();
            setModal(undefined);
          }}
          open
        />
      )}
      {modal?.m === "deleteItem" && modal?.item_id && (
        <ConfirmationDialog
          title={t("confirmDeleteItemTitle")}
          description={t("confirmDeleteItemText")}
          onSuccess={handleDeleteItem}
          onClose={() => {
            modal?.resolve();
            setModal(undefined);
          }}
          open
        />
      )}
      {modal?.m === "sendInvoice" && (
        <ConfirmationDialog
          title={t("confirmationTitle")}
          description={t("sendInvoiceConfirmation")}
          onSuccess={sendInvoiceEmail}
          onClose={() => {
            modal?.resolve();
            setModal(undefined);
          }}
          open
        />
      )}
      {modal?.m === "copyInvoice" && (
        <CopyInvoiceDialog
          onCopy={copyInvoice}
          onClose={() => {
            modal?.resolve();
            setModal(undefined);
          }}
        />
      )}
      {invoice?.contract && modal?.m === "loadFromContract" && (
        <LoadFromContractDialog
          contractId={invoice.contract}
          onLoad={modal.resolve}
          onClose={() => {
            setModal(undefined);
          }}
        />
      )}
    </>
  );
};

const useModalStyles = makeStyles((theme) =>
  createStyles({
    content: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    actions: {
      display: "flex",
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2)
    }
  })
);

type CopyInvoiceDialogProps = {
  onCopy: (quantityFactor: number) => Promise<void>;
  onClose: () => void;
};

const CopyInvoiceDialog: React.FC<CopyInvoiceDialogProps> = ({
  onCopy,
  onClose
}) => {
  const classes = useModalStyles();
  const t = useTranslate("InvoiceView");
  const [loading, setLoading] = useState(false);
  const [quantityFactor, setQuantityFactor] = useState(1);

  const handleCopy = () => {
    setLoading(true);
    void onCopy(quantityFactor).finally(() => onClose());
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>{t("copyInvoiceTitle")}</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>{t("copyInvoiceWarning")}</DialogContentText>
        <div>
          <Select
            value={quantityFactor}
            onChange={(e) => setQuantityFactor(e.target.value as number)}
          >
            <MenuItem value={-1}>{t("creditAll")}</MenuItem>
            <MenuItem value={1}>{t("copyAll")}</MenuItem>
          </Select>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          aria-label="cancel"
          onClick={() => onClose()}
          color="primary"
        >
          {t("cancelLabel")}
        </Button>
        <Button
          variant="contained"
          aria-label="confirm"
          startIcon={loading ? <CircularProgress size={20} /> : undefined}
          onClick={() => handleCopy()}
          disabled={loading}
          color="primary"
        >
          {t("copyLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type LoadFromContractDialogProps = {
  contractId: number,
  onLoad: (item: { title: string, fortnox_type: string, unit_price: string } | undefined) => void;
  onClose: () => void;
};

type ManualItem = { id: number, name: string, task_name: string | null, unit_price: number, fortnox_type: number | null }

const LoadFromContractDialog: React.FC<LoadFromContractDialogProps> = ({
  contractId,
  onLoad,
  onClose
}) => {
  const classes = useModalStyles();
  const t = useTranslate("InvoiceView");
  const [manualItems, setManualItems] = useState<ManualItem[] | undefined>()

  const handleLoad = (manualItem: ManualItem) => {
    onLoad({
      title: manualItem.name,
      unit_price: manualItem.unit_price.toString(),
      fortnox_type: manualItem.fortnox_type && manualItem.fortnox_type >= 0
        ? manualItem.fortnox_type?.toString()
        : ""
    })
    onClose();
  };

  const handleClose = () => {
    onLoad(undefined)
    onClose()
  }

  useEffect(() => {
    let alive = true;
    getContractManualItemsAPI(contractId)
      .then(response => {
        alive && setManualItems(response)
      })
    return () => { alive = false; }
  }, [contractId])

  return (
    <Dialog open={true} onClose={handleClose} fullWidth>
      <DialogTitle>{t("loadFromContractTitle")}</DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          {manualItems ? manualItems.map(mi => (
            <MenuItem style={{ justifyContent: "space-between" }} key={mi.id} onClick={() => handleLoad(mi)}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <LocalOffer />
                <div style={{ marginLeft: "10px" }}>
                  <div>{mi.name}</div>
                  <div style={{ color: "#777", fontWeight: "lighter" }}>{mi.task_name ?? t("basePrice")}</div>
                </div>
              </div>
              <div>{niceAmount(mi.unit_price)}</div>
            </MenuItem>
          )) : <div><CircularProgress /></div>}
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          aria-label="cancel"
          onClick={handleClose}
          color="primary"
        >
          {t("cancelLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default InvoiceView;
